.notification-item {
  padding: 5px;
  color: #000;
  display: block;
  border-radius: 5px;
  font-size: 1vw;
  font-weight: 600;
}
.jq-toast-wrap{
  z-index:999999999999 !important
}
.new-row{
  margin-left: 0px;
}
.label{
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.label-success{
  background-color:#17c27d;
}
.label-danger{
  background-color:#fa5436;
}
.primary{
  background-color:#1180b0;
}