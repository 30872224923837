.pull-right .dropdown-menu-2:before {
  left: auto;
  right: 12px;
}
.open>.dropdown-menu-2 {
  display: block;
}
.product-card .tools .dropdown-menu-2 li:not(:last-child) {
  border-bottom: 1px solid #CED9EE;
}
.dropdown-menu-2:before {
  border-bottom: 11px solid rgba(0, 0, 0, 0.2);
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -11px;
}
.dropdown-menu-2:after {
  border-bottom: 11px solid #FFFFFF;
  border-left: 11px solid rgba(0, 0, 0, 0);
  border-right: 11px solid rgba(0, 0, 0, 0);
  content: "";
  display: inline-block;
  position: absolute;
  left: 12px;
  top: -10px;
}
.pull-right .dropdown-menu-2:after {
  left: auto;
  right: 12px;
}
.dropdown-menu-2 {
  margin: 0;
  padding: 0;
  z-index: 9000;
  -webkit-box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
  box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.125);
}
.dropdown-menu-2 {
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  display: none;
  float: left;
  min-width: 160px;
  padding: 5px 0;
  margin: 2px 0 0;
  font-size: 14px;
  text-align: left;
  list-style: none;
  background-color: #fff;
  -webkit-background-clip: padding-box;
  background-clip: padding-box;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, .15);
  border-radius: 4px;
  -webkit-box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
  box-shadow: 0 6px 12px rgba(0, 0, 0, .175);
}
.product-card .pull-right .dropdown-menu-2 {
  right: -11px;
}
.pull-right .dropdown-menu-2 {
  right: 0;
  left: auto;
}
.pull-right>.dropdown-menu-2 {
  right: 0;
  left: auto;
}
.input-group-addon{
  width: min-content;
}
.flex-bar-start{
  padding: 10px;
}
.flex-center {
  display: flex;
  justify-content: center;
}
#minimizeSidebar {
  display: flex;
  color: white;
  width: 38px;
  height: 38px;
  padding: 9px;
  justify-content: center;
  align-items: center;
  align-content: center;
}
.toolbar-control-item {
  height: 40px !important;
  width: 175px;
}
.sidebar1-wrapper {
  overflow: hidden;
}
svg:not(:root) {
  opacity: 1000;
  z-index: 1000;
}
#componentsExamples {
  opacity: 1000;
  z-index: 1000;
}
.nav1 {
  position: relative;
  opacity: 1000;
  z-index: 1000;
}
li{
  padding: 5px 0 5px 0;
}
.spinner-block {
  height: 60px;
  width: 60px;
  margin: 0px auto;
  animation: rotation 0.6s infinite linear;
  border-top: 6px solid rgb(8 31 39 / 80%);
  border-radius: 100%;
}
.span-type-account {
  display: flex;
  justify-content: center;
  align-items: baseline;
}
.info-card-container custom-padding{
  display: flex;
}
.spinner16-wrapper {
  width: 100vw;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  /* z-index: 9999; */
}
.sidebar1 .nav1 [data-toggle="collapse"] ~ div > ul > li > a {
  opacity: 1000;
  z-index: 1000;
}
.css-t3ipsp-control:hover{border-color: #c6c7ca !important;}
.css-t3ipsp-control:hover{
  border-color: transparent !important;
}
.css-1u9des2-indicatorSeparator{
  display: none;
}
.a-drop {
  display: flex;
  align-items: flex-end;
  margin-left: 10px;
  padding: 10px;
}
.span-drop {
  margin-left: 10px;
}
.svg-drop {
  margin-bottom: 3px;
}