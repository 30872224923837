@media (min-width: 1200px){
  .container {
    max-width: 1350px;
  }

}

a,
a:hover,
.btn:not(:disabled):not(.disabled) {
  text-decoration: none;
}

a,
.service-block p,
.sub_category_title h3,
.blog-block-footer p,
.topbanner_banner .owl-dots button,
.toggle_on span,
.toggle_on i,
.item-2 .item-2-title,
.item-3 .item-3-title,
.color_pick label::after,
.simaple_products,
.product_v2,
.product_hover_height,
.social_product,
.product_v3,
.product_v3:before,
.read_more_product,
.side-nav .category-submenu,
.inner_sidebar_box h4 i,
.sidebar_position h4 i,
.whatsapp_product .border,
.small_products,
.product_slider .slide-arrow,
.produc_img a i,
.form_rev_label button,
.form_btn .bg_blue,
.form_btn_1 .border {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.side-nav,
.side-nav .mobile-link,
.categories-links .mobile-link,
.notification-dropdown,
.btn-grey,
.dropdown-menu#cart-dropdown,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a,
.blog-block .blog-block-image,
.blog-block .blog-block-image .blog-block-cover,
.blog-block .blog-block-details,
.rfq-2 .rfq-2-image .rfq-2-cover,
.rfq-2 .rfq-2-details,
.btn-blue,
.service-block,
.service-block h2,
.service-block i,
.rfq-block,
.sub-category,
.buyer-logo,
.item-2,
.item-2 .item-2-image img,
.item-3,
.item-3 .item-3-image img {
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
}

a:hover,
a.text-dark:focus,
a.text-dark:hover,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a:hover,
.side-nav .submenu-section-link a:hover,
.links.header-section .btn-white-orange:hover,
.service-block:hover p,
.service-block:hover p i,
.sub_category_title:hover h3,
.blog-block:hover .blog-block-footer p,
.processing_machines_title a:hover,
.toggle_on:hover span,
.toggle_on:hover i,
.item-2 .item-2-title:hover,
.item-3 .item-3-title:hover,
.phone_product a:hover,
.email_product a:hover,
.product_v1 h3 a:hover,
.product_redirect a:hover,
.breadcrumb_list a:hover,
.breadcrumb_list a.active:hover,
.product_slider .slide-arrow:hover,
.produc_img a i:hover,
.produc_img a:focus,
.double-sec-heading a:hover {
  color: #f44336;
}

.text_blue,
.links.header-section .btn-white-orange,
.submenu-section-title a.btn-white,
.testimonial-info h3,
.blog-block-footer p,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.service-block .service-img i,
.processing_machines_title a,
.service-block p i,
.service-block p i,
.deal-right-sec ul li span,
#sidebar .fillter_side h4,
.bg_light_blue a,
.bg_light_blue,
.product_fillter_box .nice-select span.current,
.fillter_gride_box .active i,
.pagination_next_prev a:hover,
.double-sec-heading a {
  color: #1180b0;
}

.text_dark,
.product_title_inner h5,
.flag_in_since .media-body .media span,
.breadcrumb_list a,
.product_v1 h3 a,
.list .product_tag ul li a,
.arrow_top_down,
.cus_box_check label,
#sidebar h4,
.range_slide_drop span.current,
.single_pro_title h2,
.short_title h4,
.short_title li span,
.available_offers span,
.single_tag li a,
.produc_details_tab .nav-tabs a,
.pinter-top-sec .inner_page_title p a,
.sub_category_title h3,
.sec-heading,
.short_desc_tab .short_title ul li,
.ans_text,
.produc_details_tab .nav-tabs .nav-link.active,
.reviews_form_title h4,
.form_rating label,
.request_quotation_form h4,
.qty-input input,
.request_quotation_form .nice-select span.current,
.myaccordion .card-header button,
.myaccordion .card-header button i,
.master_outlet_title .media-body span,
.master_outlet_title h2,
.master_outlet_title h3,
.outlet_opetion span,
.supplier_contact_2,
.supplier_contact_3,
.supplier_contact_4 .nice-select span.current {
  color: #333333;
}

.inner_sidebar_box h4 i,
.single_pro_title span,
.single_price_list strike,
.breadcrumb_list a.active,
.que_text,
.main_category_count,
.item-2-mq,
.to_text_range,
.product_fillter_box .country_fill .nice-select span.current,
.small_text_lab,
.responce_rate h6,
.product_v2 strike,
.inner_member span,
.flag_in_since h6,
.phone_product a,
.email_product a,
.this_seller_point span,
.product_tag h6,
.short_title ul li,
.pinter-top-sec .inner_page_title p,
.request-right-sec .control a.bttn,
.request-right-sec .control .bttn,
.quantity_text label,
.quantity_text .less,
.quantity_text .more,
.item_2_slider_products.owl-carousel .owl-nav button.owl-prev,
.item_2_slider_products.owl-carousel .owl-nav button.owl-next,
.supplier_contact_2 i,
.supplier_contact_4 > span {
  color: #666666;
}

.form_search_icon i,
.form_search_icon a,
.config-select .fa-chevron-down,
.notification-msg .notification-icon,
.sec-heading:after,
.home_slider.owl-carousel .owl-nav button.owl-next,
.home_slider.owl-carousel .owl-nav button.owl-prev,
.item-3-slider.owl-carousel .owl-nav button.owl-next,
.item-3-slider.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.cus_box_check label:before,
.color_picker .color_pick label:before,
.slide-arrow.slick-arrow {
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.select_def_box,
.home_wrapper:before,
.config-select .fa-chevron-down,
.config-select .config-select-options,
.config-select i,
.not_number,
.side-nav .category-submenu,
.notification-dropdown,
.notification-dropdown:before,
.notification-msg .notification-icon,
.topbanner_text_right_col .home-side-banner:before,
.del-cart-item,
.home_slider.owl-carousel .owl-nav button.owl-next,
.home_slider.owl-carousel .owl-nav button.owl-prev,
.topbanner_banner .owl-dots,
.text_head_box_1 span:before,
.topbanner_text .categories-links .mobile-link:hover:after,
.sec-heading:after,
.testimonial-body:before,
.blog-block .blog-block-image .blog-block-cover,
.owl-nav,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.stats-inner:before,
.border_input:after,
.rfq-2:hover .rfq-2-cover,
.service-img,
.contact-info img,
.newsletter-icon,
.short_title ul li:before,
.cus_box_check label:before,
.product_v3,
.product_v3:before,
.sim_box_text:before,
.sim_box_text:after,
.color_pick label::after,
.color_picker .color_pick label:before,
.cus_box_check input[type="checkbox"]:checked + label::after,
.request_quotation_form .product_fillter_box .nice-select:before {
  position: absolute;
}

.orange,
.read_more_product a:hover,
.form_rev_label button:hover,
.form_btn .bg_blue:hover,
.form_btn_1 .border:hover {
  background-color: #17a2de;
}

.btn-blue:hover,
.form_btn_1 .border:hover {
  border-color: #17a2de;
}

.read_more_product a:hover,
.whatsapp_product a:hover,
.shopping-cart .dropdown-menu#cart-dropdown .store-link1,
.shopping-cart .dropdown-menu#cart-dropdown .store-link1:hover,
.btn-blue:hover,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-title,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a:hover,
.home_slider.owl-carousel .owl-nav button.owl-next,
.home_slider.owl-carousel .owl-nav button.owl-prev,
.testimonial-sec .home-section-title,
.testimonial-sec .home-section,
.testimonial-sec .home-section .home-section-title h5,
.stats-title,
.stats-inner .sec-heading,
.number_pages a:hover,
.number_pages a.active,
.btn-blue,
.rfq-2 .rfq-2-details .btn-blue:hover,
.service-block:hover span,
.blue_round_circule a:hover,
.contact_info_body a:hover,
.newsletter-text h3,
.newsletter-right button,
.play_icon_top:hover,
.contact-info a:hover,
.copyright-sec p a:hover,
.footer-social a:hover,
.deal-right-sec h3,
.deal-right-sec p,
.deal-right-sec a:hover,
.stats-details,
.form_btn_1 .border:hover,
.supplier_container .owl-carousel .owl-nav button.owl-prev:hover,
.supplier_container .owl-carousel .owl-nav button.owl-next:hover {
  color: #fff;
}

.simaple_products:hover .product_v3:before,
.color_pick_1 label:before,
.noUi-horizontal .noUi-handle,
.deal-right-sec ul li span,
.deal-right-sec a,
.processing_machines_title a,
.stats-wrapper,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev,
.select_def_box,
.white-box {
  background-color: #fff;
}

.text_green {
  color: #47b604;
}

.font_14,
body,
.cart_totla,
.right_menu a,
.side-nav .mobile-link,
.cart-product-info a,
.btn-white,
.star-rating i,
.stats-details,
.newsletter-right-subtitle,
.main_category_count,
.home-section-title .btn-white i,
.home-section-title .btn-white,
.newsletter-right button,
.item-2 .item-2-price,
.service-block span,
.newsletter-text p,
.footer-info p,
.contact-info a,
.contact-info p,
.copyright-sec p,
.footer-social p,
.item-3 .item-3-price,
.tranding_brand_list .whatsapp_product .border {
  font-size: 14px;
}

.bg_blue,
.submit_btn_header,
.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a,
.border_input:after,
.rfq-block .rfq-tag,
.deal-right-sec a:hover,
.cus_box_check input[type="checkbox"]:checked + label::after,
.sim_box_text:after,
.number_pages a:hover,
.number_pages a.active,
.submit_btn_header,
.supplier_container .owl-carousel .owl-nav button:hover {
  background-color: #1180b0;
}

.font_16,
.request_quotation_form .product_fillter_box .nice-select span.current {
  font-size: 16px;
}

.font_18,
.request_quotation_form .product_fillter_box .nice-select:before,
.tranding_brand_list .phone_product .border,
.tranding_brand_list .email_product .border {
  font-size: 18px;
}

.config_select_def {
  cursor: pointer;
}

#loader {
  z-index: 999999;
  top: 0;
  left: 0;
}

.flex_wrap {
  flex-wrap: wrap;
}

html.active,
html.active body {
  overflow: hidden;
}

body {
  background-color: #f2f3f7;
  color: #333;
  line-height: 1.4;
}

html,
body {
  font-family: "Poppins", sans-serif;
  /* background-color: #fafafb; */
  background-color: #eff0f5;
  overflow-x: hidden;
}

.bold_400,
.request_quotation_form .product_fillter_box .nice-select span.current {
  font-weight: 400;
}

.bold_500 {
  font-weight: 500;
}

.bold_600 {
  font-weight: 600;
}

.bold_700 {
  font-weight: 700;
}

input,
button,
button:focus,
.btn:not(:disabled):not(.disabled) {
  outline: none;
  box-shadow: none;
}

main {
  padding-bottom: 30px;
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
span,
small,
b,
div {
  margin-bottom: 0;
}

ul {
  list-style: none;
}

.home_wrapper {
  margin-top: 15px;
}

.home_wrapper:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 580px;
  background: #4ea2ff;
  background: -moz-linear-gradient(to bottom, #c1deea 0%, #d1e7ff00 100%);
  background: -webkit-linear-gradient(to bottom, #c1deea 0%, #d1e7ff00 100%);
  background: linear-gradient(to bottom, #c1deea 0%, #d1e7ff00 100%);
  background: transparent;
}

.produc_page.home_wrapper:before,
.shap-page.home_wrapper:before {
  display: none;
}

#myHeader,
.logo_wrap img,
.logo_search_group .menu_list_hover a,
.form_search_icon input,
.menu_list_hover .mr-md-4,
.header_container,
.logo_left_search,
.blog-block-footer p {
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
}

.bg_blue {
  background-color: #1180b0;
}

.overflow-hidden {
  overflow: hidden;
}

/*header-css*/
.form_search_icon input {
  background-color: #f1f2f6;
  border-color: #f1f2f6;
  border-radius: 50px;
  width: 400px;
  height: 45px;
  font-size: 18px;
}

.form_search_icon input,
.form_search_icon i {
  color: #92949e;
}

.form_search_icon i,
.form_search_icon a {
  left: 15px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 18px;
}

.form_search_icon a {
  left: auto;
  right: 15px;
}

.menu_list_hover a {
  font-size: 18px;
}

.sticky {
  position: fixed;
  top: 0;
  z-index: 999;
}

.payouttt_offers_content p {
  max-width: 435px;
}

.cart-icon-right a.sreach-icon {
  line-height: 1.7;
  font-size: 22px;
  padding-right: 5px;
}

.dark-mode .cart-icon-right a.sreach-icon:hover .text-dark,
.cart-icon-right a.sreach-icon:hover i {
  color: #87bc31;
}

.cart-icon-right .inner-cart-icon-right {
  line-height: 1.2;
}

.cart-icon-right .inner-cart-icon-right i {
  margin-top: 4px;
}

.header_row_2 {
  transition: all 0.3s;
}

header.sticky {
  animation-name: example;
  animation-duration: 1s;
}

.sticky .header_row_2 {
  opacity: 0;
  visibility: hidden;
  padding: 0 !important;
  height: 0;
}
.opacity-0{
  opacity: 0 !important;
}
.search_cus_select{
  height: 100%;
  flex: 1;
}
.header-seach .nice-select {
  height: 100%;
  flex: 1;
  background: #fafafa;
}

@keyframes example {
  from {
    transform: translateY(-100px);
  }
  to {
    transform: translateY(0);
  }
}



@media(max-width: 450px){
  .search_position input::-webkit-input-placeholder { /* Edge */
    font-size: 12px;
  }
}
@media(max-width:389px){
  .sticky,
  #myHeader {
    box-shadow: none !important;
  }
}
@media(min-width:390px){
  .sticky,
  #myHeader {
    box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  }
}
.logo_wrap img {
  width: auto;
}

.sticky .menu_list_hover a {
  font-size: 16px;
}

.sticky .form_search_icon input {
  width: 300px;
  height: 42px;
  font-size: 16px;
}

.sticky .menu_list_hover .mr-md-4 {
  margin-right: 0;
}

#myHeader {
  top: 0;
  left: 0;
  z-index: 9999999999;
}

.shop_count {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  top: 0px;
  right: -6px;
}

.shop_cart_icon:hover .shop_bg {
  background-position: right;
}



header.sticky nav .row {
  padding: 10px 0px;
}

header nav .row.header_row_2 {
  padding: 7px 0;
}

.nice-select.open .list {
  width: 200px;
}
/* width */
.nice-select.open .list::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.nice-select.open .list::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.nice-select.open .list::-webkit-scrollbar-thumb {
  background: #bbb;
  border-radius: 10px;
}

/* Handle on hover */
.nice-select.open .list::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.cus_box_check input[type="checkbox"]:checked + label::before,
body .header-seach .search_position,
.form_rev_label button,
.form_btn_1 .border {
  border-color: #1180b0;
}

.header-seach .search_position {
  border: 2px solid;
  display: block;
  border-color: #1180b0;
  border-radius: 10px;
}

.search_position input {
  height: 40px;
  padding: 0 100px 0 120px;
  border-radius: 10px;
  background: #fafafa;
}

.submit_btn_header {
  right: 0;
  top: 0;
  background-color: #1180b0;
}

.header-seach .nice-select {
  border: 0;
  border-right: 1px solid #e6e6e6;
  border-radius: 0;
  border-radius: 10px 0 0 10px;
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  top: 0;
}

body.active .overlay {
  opacity: 1;
  visibility: visible;
}

body .nice-select .list {
  width: 100%;
}

.search_cus_select .nice-select:after,
.request_form .nice-select:after,
.fast_sourcing_form .nice-select.plain-white-dropdown:after {
  font-size: 20px;
  top: 1px;
  right: 2px;
  color: #171712;
}

.nice-select .current,
.search_position input::placeholder {
  text-align: initial;
}

.submit_btn_header button {
  cursor: pointer;
  border-radius: 0 5px 5px 0;
}

.shop_list a i {
  font-size: 22px;
}

.shop_list > a {
  display: flex;
  height: 25px;
}

.cart_totla {
  font-size: 14px;
}

.config_select_def > span > img {
  width: 22px;
}

.select_def_box {
  position: absolute;
  background-color: #fff;
  padding: 30px 20px;
  width: 290px;
  left: 0;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.select_def_box.intro {
  opacity: 1;
  visibility: visible;
  box-shadow: 1px 1px 1px 1px rgba(0, 0, 0, 0.1);
}

.cat_menu_text i {
  font-size: 18px;
}

.cat_menu_text span {
  font-size: 14px;
  padding-left: 8px;
}

.right_menu a {
  width: max-content;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
}

.links.header-section .btn-white-orange {
  color: #1180b0;
}

.orange {
  background-color: #f13f1a;
}

.not_number {
  color: white;
  width: 15px;
  height: 15px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  right: 17px;
  top: -10px;
  font-size: 10px;
}

.shopping-cart .orange.not_number {
  left: 18px;
  top: -3px;
}

.envelope_list .not_number {
  right: 7px;
}

.hot_label {
  background: #f12711;
  background: -webkit-linear-gradient(to right, #f5af19, #f12711);
  background: linear-gradient(to right, #f5af19, #f12711);
  border-radius: 6px;
  font-size: 11px;
  padding: 1px 8px;
  top: 0;
  right: -14px;
}

header .header-section.config .config-select {
  border: 1px solid #c3c6cf;
  padding: 8px 20px;
}

.logo_wrap img {
  max-width: 150px;
}

.submit_btn_header i {
  font-size: 16px;
}

.user-header-dropdown {
  position: absolute;
  top: calc(100% + 10px);
  background: white;
  padding: 10px 15px;
  z-index: 9;
  border: 1px solid #ddd;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  border-radius: 5px;
  min-width: 222px;
}

.user-header-dropdown {
  margin: 0;
  left: 0;
}

.sign_and_login:hover .user-header-dropdown {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.user-header-dropdown:before {
  content: "";
  position: absolute;
  top: -14px;
  left: 7px;
  border: 7px solid transparent;
  border-bottom-color: #dddddd;
}

.user-header-dropdown:before {
  left: 176px;
}

.user-header-dropdown .user-header-dropdown-header {
  border-bottom: 1px solid #e2e2e2;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  flex-flow: column;
}

.user-header-dropdown .user-header-dropdown-header span {
  font-weight: bold;
}

.user-header-dropdown .user-header-dropdown-header .user-email {
  margin-bottom: 5px;
  color: #008cbd;
  font-weight: normal;
}

.user-header-dropdown .user-header-dropdown-links {
  display: flex;
  flex-flow: column;
}

.user-header-dropdown .user-header-dropdown-links .user-header-link {
  margin: 4px 0;
}

.user-header-dropdown .user-header-dropdown-links .user-header-link.logout {
  border-top: 1px solid #e2e2e2;
  padding-top: 4px;
}

.user-header-dropdown .user-header-dropdown-links .user-header-link a {
  color: #a4a4a4;
  text-decoration: none;
}

.user-header-dropdown .user-header-dropdown-links .user-header-link.logout a {
  color: red;
}

.side-nav-close-section {
  cursor: pointer;
  font-size: 15px;
  background: #008cbd;
  height: 25px;
  width: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  cursor: pointer;
  margin-left: auto;
  margin-right: 15px;
  margin-bottom: 10px;
}

.categories-links .mobile-link i {
  font-size: 16px;
}

.mobile-link > p {
  display: flex;
  justify-content: space-between;
  width: 100%;
  font-size: 14px;
  margin: 0;
}

.mobile-link > p > a {
  display: flex;
  align-items: center;
}

.side-nav {
  position: fixed;
  background: white;
  left: 0;
  top: 0;
  padding: 15px 0;
  min-height: 100%;
  width: 360px;
  transform: translateX(-360px);
  transition: all 0.3s ease-in-out;
}

header .side-nav {
  /* overflow-y: auto; */
  height: 100vh;
}

.side-nav.active {
  transform: translateX(0);
  box-shadow: 3px -2px 14px 1px rgba(0, 0, 0, 0.1);
}

.toggle_on {
  cursor: pointer;
}

.side-nav .categories-links {
  display: flex;
  flex-flow: column;
  position: relative;
}

header .side-nav .categories-links {
  overflow: scroll;
  direction: rtl;
  height: 100vh;
}

.side-nav .mobile-link {
  direction: ltr;
  position: relative;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.categories-links .mobile-link {
  color: #666;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 11px 15px;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;
  border-bottom: 1px solid #eee;
}

.side-nav .category-submenu {
  display: flex;
  background: white;
  border: 1px solid #e7e7e7;
  margin-left: 0px;
  flex-flow: row wrap;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s;
}

.side-nav .category-submenu {
  position: absolute;
  top: 0;
  left: 100%;
  right: auto;
  width: 800px;
  max-width: 100vw;
  -moz-box-shadow: 0 1px 5px #e7e7e7;
  -webkit-box-shadow: 0 1px 5px #e7e7e7;
  box-shadow: 0 1px 5px #e7e7e7;
  -moz-border-radius: 4px;
  -webkit-border-radius: 4px;
  border-radius: 4px;
  padding: 20px;
  height: 100vh;
  align-items: flex-start;
  align-content: flex-start;
  overflow-y: auto;
}

.side-nav .category-submenu::-webkit-scrollbar,
.side-nav .categories-links::-webkit-scrollbar {
  width: 0.4em;
}

.side-nav .category-submenu::-webkit-scrollbar-track,
.side-nav .categories-links::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

.side-nav .category-submenu::-webkit-scrollbar-thumb,
.side-nav .categories-links::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}

@media screen and (min-width: 768px) {
  .side-nav .mobile-link:hover .category-submenu,
  .side-nav .category-submenu.active {
    display: flex;
    visibility: visible;
    opacity: 1;
  }
}

.side-nav .submenu-section {
  width: 33.333333333%;
}

.side-nav .submenu-section-title {
  margin: 0px 0px 10px 0px;
  font-size: 14px;
}

.submenu-section-title a {
  color: #1180b0;
}

.side-nav .submenu-section-link {
  margin: 0;
  line-height: 15px;
  padding-top: 10px;
}

.side-nav .submenu-section-link a {
  color: #333333;
  font-size: 13px;
  font-weight: 100;
}

.side-nav .submenu-section-link.more a {
  color: #f44336;
}

.side-nav .submenu-section {
  margin: 0px 0px 10px 0px;
}

.side-nav .submenu-section-title a {
  font-weight: 200;
}

.side-nav .submenu-section-link.more a:hover {
  color: #1180b0;
}

.or_text {
  color: #9e9e9e;
}

/*email*/
.notification-dropdown {
  position: absolute;
  background: white;
  left: 50%;
  transform: translateX(-50%);
  top: calc(100% + 15px);
  width: 350px;
  max-width: 100vw;
  margin: 0 !important;
  padding: 0;
  border: 1px solid #bbb;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;
}

.IsNotifications .notification-dropdown {
  left: auto;
  right: 0;
  transform: translateX(0);
  z-index: 99;
}

.IsNotifications:hover .notification-dropdown {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.notification-dropdown-body {
  display: flex;
  flex-flow: column;
  padding: 10px 20px;
  max-height: 350px;
  overflow-y: auto;
}

.notification-dropdown-body.minicart-scroll {
  height: 250px;
  overflow-y: scroll;
  padding: 0 0;
  margin: 0;
}

.notification-msg {
  padding: 0 15px;
  display: flex;
  align-items: center;
  position: relative;
  background: #e9e9e9;
}
.notification-msg.new {
  background: white;
}

.notification-msg a {
  color: #828282;
  padding: 10px 0 10px 15px;
}

.notification-dropdown:before {
  content: "";
  border-style: solid;
  border-width: 9px;
  border-color: transparent;
  border-top-width: 0;
  border-bottom: 9px solid #ddd;
  width: 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  position: absolute;
  top: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.notification-dropdown:before {
  left: auto;
  transform: translateX(-50%);
  right: -9px;
}

.notification-msg .notification-icon {
  width: 10px;
  height: 10px;
  background: #3cd83c;
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border-radius: 50%;
}

.notification-dropdown-footer {
  display: flex;
  justify-content: center;
  padding: 10px;
  border-top: 1px solid #bbb;
  margin: 0 15px 0;
}

.btn-grey {
  background: #f4f4f4;
  color: black;
  padding: 10px 15px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.notification-msg:last-child {
  border: 0;
}

/*//cart*/
.dropdown-menu#cart-dropdown {
  width: max-content;
  padding: 20px;
  max-width: 100vw;
  display: block;
  right: 0;
  top: 81px;
  visibility: hidden;
  opacity: 0;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
  box-shadow: 0 15px 20px 0 rgba(0, 0, 0, 0.25);
  background: #ffffff;
  left: auto !important;
}

.dropdown-menu#cart-dropdown {
  width: 320px;
}

.shopping-cart:hover .dropdown-menu#cart-dropdown {
  visibility: visible;
  opacity: 1;
  top: 100%;
}

.minicart-scroll {
  max-height: 250px;
  overflow-y: scroll;
  padding: 0 10px;
  margin: 0 -20px;
}

.card-productlist {
  position: relative;
  padding: 0 0 15px;
  border-bottom: 1px solid #e2e2e2;
}

.card-productlist .cart-product-img {
  float: left;
  margin-right: 15px;
  width: 60px;
  overflow: hidden;
  text-align: center;
}

.cart-product-img img {
  max-width: 80%;
  max-height: 80px;
}

.cart-product-info {
  display: inline-block;
}

.cart-product-info a {
  display: block;
  margin-bottom: 5px;
  font-size: 14px;
  overflow: hidden;
  color: #191919;
  line-height: 1.1;
  white-space: nowrap;
  text-overflow: ellipsis;
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -o-transition: all 0.3s;
  -ms-transition: all 0.3s;
  width: 180px;
}

.shopping-cart .dropdown-menu#cart-dropdown a {
  font-size: 15px;
}

.cart-product-info .quantity-line {
  margin-top: 10px;
  line-height: 1;
  font-size: 13px;
}

.cart-product-info .quantity {
  margin-right: 5px;
  display: inline-block;
}

.cart-product-info p.price-line {
  margin-bottom: 0;
  color: #666;
  font-size: 15px;
  font-weight: 700;
  line-height: 1.7;
  margin-top: 0;
}

.del-cart-item {
  position: absolute;
  right: 0;
  top: 0;
  line-height: normal;
}

.shopping-cart .dropdown-menu#cart-dropdown a {
  font-size: 15px;
}

.shopping-cart .dropdown-menu#cart-dropdown a.remove {
  color: #c3c3c3;
}

.total-sec {
  padding-top: 15px;
}

.total-sec b.content-subhead {
  float: right;
}

.shopping-cart .dropdown-menu#cart-dropdown .store-link1 {
  margin-bottom: 0;
  background: #1180b0;
  color: #fff;
  margin-top: 20px;
  font-weight: 700;
  font-size: 12px;
  padding: 10px 30px;
  text-transform: uppercase;
  border: 2px solid transparent;
  transition: all 0.3s;
}

.shopping-cart .dropdown-menu#cart-dropdown .store-link1:hover,
.btn-blue:hover {
  background-color: #f44336;
  color: #fff;
}

.btn-blue:hover {
  border-color: #f44336;
}

.minicart-scroll {
  max-height: 250px;
  overflow-y: scroll;
}

.total-sec {
  /* padding-top: 15px; */
}

.minicart-scroll::-webkit-scrollbar-track {
  background-color: #f2f2f2;
  width: 5px;
}

.minicart-scroll::-webkit-scrollbar-thumb {
  background-color: #cdcdcd;
  cursor: pointer;
}

.minicart-scroll::-webkit-scrollbar {
  width: 5px;
}

.home_layer {
  max-height: 270px;
}

@media screen and (max-width: 575px) {
  .search_scroll > i {
    font-size: 20px;
    top: -2px;
  }

  .home_layer {
    max-height: 150px;
  }
}

@media screen and (max-width: 991px) {
  header nav .row {
    padding: 10px 0px;
  }

  .menu-icon-mobile {
    padding-left: 15px;
  }

  .dark-mode .menu-icon-mobile.text-dark {
    color: #cacaca;
  }

  .cart-icon-right a.sreach-icon {
    padding-right: 0px;
  }

  header {
    padding: 0;
  }

  .right_menu_responsive .right_menu a {
    padding: 0 8px;
    font-size: 12px;
    display: block;
    text-align: left;
  }

  .hot_label {
    font-size: 9px;
    padding: 1px 8px;
    top: -12px;
    right: -14px;
  }

  .right_menu_responsive .right_menu ul {
    margin-top: 0;
    justify-content: flex-end;
  }
 .header-lower {
   height: 50px !important;
   display: flex !important;
   align-content: space-around !important;
 }

  .menu_list_hover li {
    width: 100% !important;
  }

  header nav .row.header_row_2 {
    padding: 12px 0;
  }

  .dark-mode .right_menu_responsive {
    background-color: #000;
  }

  .right_menu_responsive.active {
    right: 0;
  }

  .dark-mode .right_menu_responsive.active {
    box-shadow: 0px 0px 34px 0px rgba(255, 255, 255, 0.2);
  }

  .responsive_toggle a {
    font-size: 25px;
  }

  .right_menu_close {
    top: 5px;
    left: 10px;
    font-size: 20px;
  }

  .dark-mode .right_menu_close a {
    color: #ffffff;
  }

  .menu_list_hover {
    display: flex;
    flex-wrap: wrap;
    flex-flow: column;
  }

  .menu_list_hover .active,
  .menu_list_hover > li:hover {
    border-radius: 0;
  }

  .right_menu_responsive {
    padding-left: 0;
    padding-right: 0;
  }

  body.active,
  html.active {
    overflow: hidden;
  }

  .right_menu_close a i {
    font-size: 17px;
    top: 6px;
    position: relative;
  }
}

@media screen and (max-width: 575px) {
  .responsive_small_devi {
    flex-wrap: wrap;
  }

  .dashboard_container .select_filed_1 {
    width: 100%;
  }

  .dashboard_container .fillter_search_dashboard input,
  .dashboard_container .select_filed .nice-select {
    height: 41px;
  }

  .sticky .header_container {
    padding: 0px 15px;
  }

  .logo_left_search {
    position: fixed;
    top: -100%;
    width: 100%;
    left: 0;
    background-color: #fff;
    z-index: 999;
  }

  .logo_left_search.intro {
    top: 0;
  }

  .form_search_icon input,
  .form_search_icon {
    width: 100%;
  }

  .form_search_icon {
    padding: 35px 10px;
  }

  .form_search_icon i {
    left: 27px;
    font-size: 14px;
  }

  .form_search_icon img {
    right: 25px;
  }

  .form_search_icon input {
    font-size: 15px;
  }

  .country_collapse #pollution_india_id .form_search_icon {
    padding: 5px 10px;
  }
}

@media screen and (min-width: 1650px) {
  .container {
    max-width: 1580px;
  }

  .col-20 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }

  .col-55 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 63%;
    flex: 0 0 60%;
    max-width: 60%;
  }
}

/*main-css*/
.home-side-banner1 {
}

.home-side-banner2 {
}

.home-side-banner3 {
}

.topbanner_text_right_col .home-side-banner {
  flex: 1;
  position: relative;
  background-size: cover;
  border-radius: 6px;
  overflow: hidden;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  border: 2px solid white;
  align-items: center;
}

.topbanner_text_right_col .home-side-banner:before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  /*background-color: rgba(0, 0, 0, 0.4);*/
  top: 0;
  left: 0;
}

.topbanner_text_right_col .home-side-banner .home-side-banner-details {
  padding: 19px 40px 16px 10px;
  width: 100%;
  z-index: 9;
}

.request_form .sec-heading img,
.double-sec-heading .sec-heading img {
  z-index: 1;
}

.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-title {
  text-transform: capitalize;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  font-weight: bold;
  margin-bottom: 10px;
  text-shadow: 2px 2px 2px rgb(0, 0, 0, 0.1);
}

.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link {
  margin-bottom: 12px;
}

.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a {
  color: #fff;
  font-size: 12px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  text-decoration: none;
  background-color: rgb(0 0 0 / 0.3);
  border-radius: 50px;
  padding: 0 10px;
  height: 26px;
  width: 94px;
  justify-content: center;
}

.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a:hover {
  background-color: #f44336;
  color: #fff;
}

.topbanner_text_right_col
.home-side-banner
.home-side-banner-details
.home-side-banner-link
a
i {
  width: auto;
  height: auto;
  text-align: center;
  line-height: 1;
  font-size: 17px;
  margin-left: 5px;
  top: 1.5px;
  position: relative;
}

.topbanner_text_right_col .home-side-banner:nth-child(2) {
  margin: 15px 0;
}

.home_slider .item,
.home_slider .owl-stage-outer {
  overflow: hidden;
}

.home_slider .item,
.home_slider .owl-stage-outer,
.home_slider .owl-stage-outer a {
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
}

.home_wrapper .side-nav {
  position: relative;
  transform: translateX(0);
  width: 100%;
  box-shadow: none;
  z-index: 99;
  min-height: 100%;
  border-radius: 6px;
}

.home_wrapper .topbanner_text .categories-links .mobile-link {
  position: static;
}

.home_wrapper .side-nav .category-submenu {
  height: 100%;
  min-height: auto;
  box-shadow: 3px -5px 40px rgba(205, 205, 212, 0.5);
  border-radius: 10px;
}

.home_slider.owl-carousel .owl-nav button.owl-next,
.home_slider.owl-carousel .owl-nav button.owl-prev {
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
  width: 30px;
  height: 60px;
  background: #ccc;
  background-color: rgba(0, 0, 0, 0.5);
  text-align: center;
  cursor: pointer;
  z-index: 10;
  border: 0;
  color: #fff;
  transition: all 0.3s;
  font-size: 18px;
  border-radius: 0;
}

.home_slider.owl-carousel .owl-nav button.owl-prev {
  left: -150px;
}

.home_slider.owl-carousel .owl-nav button.owl-next {
  right: -150px;
}

.home_slider:hover .owl-nav button.owl-prev {
  left: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.home_slider:hover .owl-nav button.owl-next {
  right: 0;
  background-color: rgba(0, 0, 0, 0.7);
}

.topbanner_banner .owl-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}

.topbanner_banner .owl-dots button {
  width: 12px;
  height: 12px;
  display: inline-block;
  border-radius: 60px;
  border: 0;
  margin: 0 2px;
  background-color: #f2f3f7;
  border: 2px solid #f2f3f7;
  padding: 0;
}

.topbanner_banner .owl-dots button.active {
  border-color: #f44336;
}

.topbanner_banner .categories-links .mobile-link {
  display: flex;
  justify-content: space-between;
}

.mobile-link > div.text_head_box_2 {
  width: 11px;
}

.text_head_box_1 span {
  position: relative;
  margin-right: 10px;
}

.text_head_box_1 span:before {
  border-radius: 20px;
  position: absolute;
  content: "";
  /*background: rgba(0, 0, 0, 0.05);*/
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}

.text_head_box_1 span img {
  width: 40px;
}

.text_head_box_1 {
  font-size: 13px;
}

.topbanner_text .categories-links .mobile-link {
  padding: 0;
  border-bottom: none;
  margin: 5px 15px;
  margin-left: 7.5px;
  margin-right: 0;
  padding-right: 15px;
  position: relative;
  padding-left: 7.5px;
}

.topbanner_text .side-nav {
  padding: 10px 0;
}

.topbanner_text .categories-links .mobile-link:hover {
  box-shadow: none
}

.topbanner_text .categories-links .mobile-link,
.topbanner_text .categories-links .mobile-link:after {
  transition: all 0.3s;
}

.topbanner_text .categories-links .mobile-link:hover:after {
  position: absolute;
  width: 8px;
  height: 100%;
  background: #fff;
  content: none;
  right: -4px;
  top: 0;
}

.fast_sourcing {
  z-index: 9;
}

.fast_sourcing_top .white-box {
  max-width: 100%;
}

.fast_sourcing .rfq-2-form-elements {
  width: 100%;
}

.fast_sourcing_form .nice-select.plain-white-dropdown {
  width: 100%;
}

.fast_sourcing_form .rfq-2-form-elements label {
  margin-bottom: 0;
  display: block;
}

.fast_sourcing_form .btn-blue {
  font-size: 16px;
  width: 100%;
}

/*new css*/
.sec-heading {
  color: #333333;
  font-weight: 700;
  position: relative;
  background: #fff;
  z-index: 5;
  font-size: 22px;
  margin: 0;
}

.sec-heading a {
  color: #333333;
  font-weight: 500;
  position: relative;
  background: #fff;
  z-index: 5;
  font-size: 18px;
  margin: 0;
}

.sec-heading a:hover {
  color: #f44336;
}

.sec-heading span {
  display: inline-block;
  background: #fff;
  position: relative;
  z-index: 556;
  padding-right: 40px;
  text-transform: capitalize;
}

.double-sec-heading {
  display: flex;
  align-items: center;
  width: 100%;
}

.double-sec-heading p {
  font-size: 12px;
  cursor: auto;
  margin-left: auto;
  color: #999;
  letter-spacing: 0.3px;
}

.double-sec-heading .sec-heading {
  width: 70%;
}

.double-sec-heading a {
  margin-left: auto;
}

.btn-white {
  background: white;
  color: #1180b0;
  padding: 10px 15px;
  cursor: pointer;
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  padding: 0;
  transition: all 0.3s;
  background-color: transparent;
  display: flex;
  align-items: center;
}

.double-sec-heading .btn-white i {
  font-size: 18px;
  padding-left: 7px;
}

.sec-heading:after {
  position: absolute;
  content: "";
  width: 100%;
  height: 1px;
  background-color: #ddd;
  top: 52%;
  transform: translateY(-50%);
  left: 0;
}

/* Testimonial */
.d-flex.start_rate {
  align-items: center;
  justify-content: space-between;
}

.white-box {
  background-color: #fff;
  box-shadow: 0px 4px 32.2px 2.8px rgba(0, 0, 0, 0.04);
  border-radius: 6px;
  padding: 40px;
}

.testimonial-sec .container {
  padding: 0 90px;
}

.testimonial-info h3 {
  margin-bottom: 0;
  color: #1180b0;
  font-size: 18px;
  font-weight: 500;
}

.testimonial-sec .container {
  z-index: 9;
  position: relative;
}

.testimonial-sec .home-section-title,
.testimonial-sec .home-section {
  background-color: transparent;
  padding: 0 !important;
  color: #fff;
  text-align: center;
  justify-content: center !important;
}

.testimonial-sec .home-section .home-section-title h5 {
  color: #fff;
}

.star-rating i.gold {
  color: #ffc106;
}

.star-rating i {
  color: #e1e1e1;
  font-size: 14px;
}

.testimonial-bottom {
  display: flex;
  align-items: flex-start;
  z-index: 9;
  position: relative;
}

.testimonial-body p {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 15px;
  line-height: 1.5;
  color: #63758a;
}

.testimonial-body {
  /*background-color: #fbfbfb;*/
  padding: 25px;
  border-radius: 10px;
  position: relative;
}

.testimonial-body:before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  width: 88%;
  height: 100%;
  background-color: #fbfbfb;
  border-radius: 6px;
}

.testimonial-slider {
  margin-top: 30px;
}

.testimonial-img {
  padding-left: 0;
}

.testimonial-img img {
  width: 70px !important;
  height: 70px;
  display: block;
}

.testimonial-slider .testimonial-img {
  padding-right: 15px;
}

.testimonial-sec .owl-carousel button.owl-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  padding: 0;
  margin: 0 5px;
  background: #fff;
  opacity: 0.5;
  border: none;
}

.testimonial-sec .owl-carousel .owl-dots {
  margin-top: 20px;
  text-align: center;
}

.testimonial-sec .owl-carousel button.owl-dot.active {
  opacity: 1;
}

@media screen and (max-width: 1500px) {
  .testimonial-info {
    padding-left: 10px;
  }
}

@media screen and (max-width: 991px) {
  .testimonial-sec {
    padding: 20px 0;
  }

  .testimonial-slider {
    margin-top: 20px;
  }

  .testimonial-bottom {
    display: block;
    text-align: center;
  }

  .testimonial-img img {
    width: 60px !important;
    height: 60px;
    margin: 0 auto 8px;
  }

  .testimonial-body p {
    font-size: 12px;
    margin-bottom: 10px;
  }

  .testimonial-info h3 {
    font-size: 16px;
    margin: 0;
  }

  .star-rating i {
    font-size: 9px;
  }

  .testimonial-body {
    padding: 15px;
  }
}

@media (min-width: 768px) and (max-width: 999px) {
  .testimonial-sec .owl-carousel .owl-dots {
    margin-top: 20px;
    text-align: center;
  }

  .testimonial-sec .owl-carousel button.owl-dot.active {
    opacity: 1;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .config_def {
    display: none;
  }

  .testimonial-img {
    width: 60px;
    height: 60px;
  }

  .testimonial-img img {
    width: 60px !important;
    height: 60px;
  }

  .testimonial-body p {
    font-size: 14px;
    margin-bottom: 10px;
  }

  .testimonial-info h3 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .star-rating i {
    font-size: 10px;
  }

  .testimonial-body {
    padding: 20px;
  }

  .testimonial-info {
    padding-left: 20px;
  }

  .testimonial-sec {
    padding: 40px 0;
  }

  body .stats-inner:before {
    background: transparent linear-gradient(90deg, #000000b2 0%, #00000000 190%);
  }
}

/******* Blog ********/
.blog-wrapper {
  display: flex;
  margin-top: 30px;
  justify-content: space-between;
}

.blog-block {
  background: white;
}

.blog-block a {
  display: flex;
  flex-flow: column;
  text-decoration: none;
}

.blog-block .blog-block-image {
  width: 100%;
  overflow: hidden;
  height: 180px;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.blog-block .blog-block-image .blog-block-cover {
  width: 100%;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position-x: right;
  transition: all 0.3s ease-in-out;
  position: absolute;
  left: 0;
  top: 0;
}

.blog-block .blog-block-details {
  background: white;
  display: flex;
  flex-flow: column;
  padding: 30px 20px;
  padding-bottom: 0;
  transition: all 0.3s ease-in-out;
}

.blog-block .blog-block-title {
  color: #343434;
  font-size: 18px;
  font-weight: 500;
  height: 50px;
  margin-bottom: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.blog-block .blog-block-footer {
  display: flex;
  justify-content: space-between;
  color: #767676;
  align-items: center;
}

.blog-block .blog-block-date {
  color: #767676;
}

.blog-block .blog-block-footer i {
  font-size: 17px;
}

.blog-block:hover .blog-block-image .blog-block-cover {
  transform: scale(1.15);
}

.blog-block:hover .blog-block-image {
  height: 180px;
}

.blog-block .blog-block-date {
  color: #f44336;
  margin-bottom: 10px;
}

.blog-block-footer p {
  margin-bottom: 0;
  display: flex;
  font-size: 16px;
  align-items: center;
  color: #1180b0;
}

.blog-block-footer p i {
  margin-left: 10px;
}

.blog-block-footer p:hover {
  color: #f44336;
}

.blog-block .blog-block-title {
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

section.home-section.container.home-section-8 {
  margin: 100px auto;
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
  border-color: #e8e8e8;
}

@media screen and (max-width: 767px) {
  .home-store .store-details .store-profile-image img {
    max-height: 150px;
  }

  .item-2 .item-2-image,
  .item-3 .item-3-image {
    height: 150px;
    margin-bottom: 10px;
  }

  .sign_and_login .user-header-dropdown {
    right: 0;
    left: auto;
  }

  .user-header-dropdown:before {
    left: 176px;
  }

  .categories_link_toggle .mobile-links-section-title {
    padding: 0 10px;
    border-bottom: 1px solid #e2e2e2;
    margin-top: 10px;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: left;
    direction: ltr;
  }

  .categories_link_toggle .mobile-link {
    transition: none;
  }

  .categories_link_toggle
  > .mobile-links-section-title.intro
  .change_icon:before {
    content: "\ea78";
  }

  body .home_slider_col {
    padding: 0;
  }

  .blog-wrapper {
    padding: 0 15px;
  }

  .blog-block .blog-block-details {
    padding: 20px 0;
  }

  .home-section .home-section-title h5 {
    font-size: 16px;
    margin-bottom: 0;
  }

  .home-section .home-section-title {
    padding: 15px 15px 0;
  }

  .rfq-create-form .nice-select span.current {
    font-size: 11px;
  }

  .rfq-create-form .nice-select {
    height: 40px;
  }

  div .form_one.form_one_on {
    z-index: 0;
  }

  .rfq-create-form .nice-select.open .list {
    width: auto;
  }

  .item-2-slider.owl-carousel .owl-dots.disabled,
  .item-2-slider.owl-carousel .owl-nav.disabled {
    display: block;
  }
}

/*owl nav css*/
.owl-nav {
  transform: translateY(-140%);
  position: absolute;
}

.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  background-color: #fff;
  border: 1px solid #e2e2e2;
  color: #1180b0;
  border-radius: 50%;
  padding: 0;
  width: 25px;
  height: 25px;
  position: absolute;
  top: 50%;
  transform: translateY(-140%);
}

.owl-carousel .owl-nav button.owl-prev {
  left: -10px;
}

.owl-carousel .owl-nav button.owl-next {
  right: 0;
}

.item-3-slider.owl-carousel .owl-nav button.owl-next,
.item-3-slider.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel .owl-nav button.owl-prev {
  transform: translateY(-50%);
  transition: all 0.3s;
}

.owl-carousel .owl-nav button.owl-next:hover,
.owl-carousel .owl-nav button.owl-prev:hover {
  color: #f44336;
}

@media screen and (min-width: 992px) {
  .owl-carousel .owl-nav button.owl-next,
  .owl-carousel .owl-nav button.owl-prev {
    width: 40px;
    height: 40px;
    font-size: 25px;
  }

  .owl-carousel .owl-nav button.owl-prev {
    left: -20px;
  }

  .owl-carousel .owl-nav button.owl-next {
    right: -20px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  body .service-block {
    width: 100%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body .blog-block .blog-block-title {
    font-size: 15px;
    margin-bottom: 5px;
  }

  body .blog-block-footer p {
    font-size: 14px;
  }

  body .blog-block .blog-block-footer i,
  body .blog-block .blog-block-date {
    font-size: 12px;
  }

  body .blog-block .blog-block-date {
    margin-bottom: 3px;
  }

  body .blog-block .blog-block-details {
    padding: 20px 10px;
  }

  body .blog-block .blog-block-details {
    height: auto;
  }

  body .blog-block .blog-block-image .blog-block-cover,
  body .blog-block .blog-block-image,
  body .blog-block:hover .blog-block-image,
  body .blog-block:hover .blog-block-image,
  body .blog-block:hover .blog-block-details {
    height: 220px;
  }

  body .blog-block:hover .blog-block-title {
    height: auto;
    margin-bottom: 0;
  }
}

@media (min-width: 992px) and (max-width: 1599px) {
  .blog-block .blog-block-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    margin-bottom: 20px;
  }

  .blog-block .blog-block-details {
    padding: 30px 20px;
  }
}

/******* End of Blog ********/

/******* Stats ********/
.stats-wrapper {
  height: 100%;
  background-color: #fff;
}

.stats-sec h2 {
}

.stats-inner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 20px 50px;
}

.stats-block {
  text-align: left;
  font-size: 21px;
  margin-top: 20px;
  padding: 0px 20px;
  flex-grow: 1;
}

.stats-block:last-child {
  border: 0;
}

.stats-sec a.btn-white {
  margin-top: 30px;
  display: inline-block;
}

.stats-inner {
  padding: 45px 50px 45px 50px;
  background-image: url(https://s101.abraacdn.com/files/counter.jpg);
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  position: relative;
}

.stats-sec .row .col-md-6:first-child {
  padding-left: 0;
  padding-right: 7px;
}

.stats-sec .row .col-md-6:last-child {
  padding-left: 7px;
  padding-right: 0;
}

.stats-inner:before {
  background-color: rgba(0, 0, 0, 0.6);
}

.stats-title {
  font-weight: bold;
  color: #fff;
  margin-bottom: 0;
  font-size: 20px;
}

.stats-details {
  color: #ffffff;
  font-size: 14px;
}

.stats-inner:before {
  position: absolute;
  border-radius: 6px;
  content: "";
  height: 100%;
  background: transparent linear-gradient(90deg, #000000b2 0%, #00000000 100%);
  top: 0;
  left: 0;
}

.stats-inner .sec-heading span {
  background: none;
  text-transform: uppercase;
  font-size: 24px;
}

.stats-inner .sec-heading {
  color: #fff;
  margin-bottom: 10px;
  width: 100%;
  background: none;
}

.stats-inner .sec-heading:after {
  display: none;
}

.home-section-title .btn-white {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  text-transform: uppercase;
  color: #ffffff;
  padding: 0;
  transition: all 0.3s;
  background-color: transparent;
}

.home-section-title .btn-white i {
  font-size: 14px;
  padding-left: 7px;
}

@media (max-width: 992px) {
  .stats-block {
    min-width: 250px;
  }
}

@media (max-width: 600px) {
  .stats-inner {
    padding: 0;
  }

  .stats-block {
    min-width: auto;
    width: 100%;
    border-bottom: 1px solid #eee;
    margin: 0;
    padding: 30px 20px;
  }
}

/******* End of Stats ********/
/******* Start of Second RFQ Form ********/
.rfq-2 {
  background: white;
  display: flex;
  position: relative;
}

.rfq-2 .rfq-2-image {
  width: 40%;
  position: relative;
  overflow: hidden;
}

.rfq-2 .rfq-2-image .rfq-2-cover {
  width: 100%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.rfq-2 .rfq-2-details {
  display: flex;
  flex-flow: column;
  width: 100%;
  margin: 0 auto;
  position: relative;
  background: white;
  transition: all 0.3s ease-in-out;
}

.rfq-2 .rfq-2-details .rfq-2-title {
  color: #343434;
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 25px;
  text-align: center;
  margin-top: 0;
}

.rfq-2-form {
  display: flex;
}

.white-box.request_form {
  height: 100%;
}

.request_form .nice-select.open ul.list {
  width: 100%;
}

.rfq-2-form-elements input:focus {
  box-shadow: 0 0 11px 0px rgba(0, 0, 0, 0.1);
}

input {
  transition: all 0.3s;
}

.rfq-2-form-elements {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: center;
}

.rfq-2-form-elements input {
  background: #fff;
  border: 1px solid #e2e2e2;
  height: 45px;
  padding: 0 12px;
  width: 100%;
  border-radius: 6px;
}

.rfq-2-form-elements input::placeholder {
}

.rfq-2-form-elements input {
  color: #222;
}

.rfq-2-form-elements label {
  margin-bottom: 10px;
  position: relative;
  border-radius: 6px;
  overflow: hidden;
}

.border_input:after {
  content: "";
  position: absolute;
  width: 100%;
  height: 2px;
  background-color: #1180b0;
  bottom: 0;
  left: 0;
  border-radius: 6px;
  transform: scale(0);
  transition: all 0.3s;
}

.border_input.border_add:after {
  transform: scale(1);
}

.border_input1 {
  width: 100%;
}

.border_input2 {
  width: calc(50% - 5px);
}

.rfq-2 .rfq-2-details .btn-blue {
  width: 100%;
}

.rfq-2:hover .rfq-2-cover {
  position: absolute;
  height: 100%;
  width: 120%;
  border-radius: 10px;
}

.request_form .nice-select {
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .owl-carousel .owl-nav button.owl-next {
    right: 0;
  }

  .owl-carousel .owl-nav button.owl-prev {
    left: 0;
  }

  .rfq-2 .rfq-2-image {
    display: none;
  }

  .rfq-2 .rfq-2-details {
    width: 100%;
  }
}

@media (min-width: 500px) {
  .rfq-2-form-elements input:nth-child(2) {
    width: calc(50% - 5px);
    margin-right: 5px;
  }

  .request_form .nice-select {
    width: calc(50% - 5px);
    margin-left: 5px;
  }
}

.btn-blue {
  color: #fff;
  padding: 10px 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border-radius: 6px;
  background: #1180b0;
  border: 1px solid #1180b0;
  font-weight: 500;
  font-size: 15px;
  height: 44px;
}

.rfq-2 .rfq-2-details .btn-blue {
  width: 100%;
}

.rfq-2 .rfq-2-details .btn-blue:hover {
  background: #f44336;
  color: #fff;
  border-color: #f44336;
}

.rfq-list {
  margin: 25px 0;
  position: relative;
  height: 30px;
  overflow: hidden;
}

.rfq-list .item-info {
  overflow: hidden;
  height: 30px;
  line-height: 15px;
  color: #666;
  -webkit-animation: scroll 25s infinite;
}

.rfq-list .item-info img {
  max-height: 10px;
}

@keyframes scroll {
  0% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  6.66667% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
  8.33333% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  15% {
    -webkit-transform: translateY(-30px);
    transform: translateY(-30px);
  }
  16.6667% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  23.3333% {
    -webkit-transform: translateY(-60px);
    transform: translateY(-60px);
  }
  25% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
  }
  31.6667% {
    -webkit-transform: translateY(-90px);
    transform: translateY(-90px);
  }
  33.3333% {
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
  }
  40% {
    -webkit-transform: translateY(-120px);
    transform: translateY(-120px);
  }
  41.6667% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
  }
  48.3333% {
    -webkit-transform: translateY(-150px);
    transform: translateY(-150px);
  }
  50% {
    -webkit-transform: translateY(-180px);
    transform: translateY(-180px);
  }
  56.6667% {
    -webkit-transform: translateY(-180px);
    transform: translateY(-180px);
  }
  58.3333% {
    -webkit-transform: translateY(-210px);
    transform: translateY(-210px);
  }
  65% {
    -webkit-transform: translateY(-210px);
    transform: translateY(-210px);
  }
  66.6667% {
    -webkit-transform: translateY(-240px);
    transform: translateY(-240px);
  }
  73.3333% {
    -webkit-transform: translateY(-240px);
    transform: translateY(-240px);
  }
  75% {
    -webkit-transform: translateY(-270px);
    transform: translateY(-270px);
  }
  81.6667% {
    -webkit-transform: translateY(-270px);
    transform: translateY(-270px);
  }
  83.3333% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }
  90% {
    -webkit-transform: translateY(-300px);
    transform: translateY(-300px);
  }
  91.6667% {
    -webkit-transform: translateY(-330px);
    transform: translateY(-330px);
  }
  98.3333% {
    -webkit-transform: translateY(-330px);
    transform: translateY(-330px);
  }
  100% {
    -webkit-transform: translateY(-360px);
    transform: translateY(-360px);
  }
}

.stats-inner .row {
  width: 100%;
}

/******* End of Second RFQ Form ********/

/* Services */
.service-block a {
  position: relative;
}

.service-img {
  position: absolute;
  left: 0;
  top: -10px;
}

.service-info {
  text-align: left;
  padding-left: 70px;
}

.service-info h2 {
  margin-top: 0;
}

.service-block span {
  color: #000;
}

.service-block p {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  color: #000;
  margin-top: 15px;
}

.service-block p i {
  margin-left: 10px;
  font-size: 16px;
}

.service-block:hover span {
  color: #fff;
}

@media screen and (max-width: 1199px) {
  .services-wrapper {
    padding: 0;
  }

  .service-img {
    position: relative;
    left: 0;
    top: 0;
  }

  .service-info {
    text-align: center;
    padding-left: 0;
  }

  .service-block p {
    justify-content: center;
  }

  header .header-section.links > div.btn-grey {
    display: none;
  }
}

@media screen and (max-width: 991px) {
  body header > .container {
    display: none !important;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  body header .header-section.bottom-links {
    display: flex;
    padding: 10px 0;
  }

  header > .d-flex {
    padding-bottom: 15px;
  }

  .home-section-3 .col_sec_in_banner.col_sec_in_banner1 {
    margin-right: auto;
  }

  .col_sec_in_banner1 li:last-child {
    margin-left: 20px;
  }

  body .home-slider .item {
    border-radius: 10px;
  }

  div .rfq-block .media img {
    margin-right: 10px;
  }

  .newsletter-right {
    margin: 0 auto 35px;
  }

  .footer-width.footer-info a:first-child img {
    max-width: 160px;
  }

  .mobile-head-section {
    display: flex;
    width: 100%;
    border-bottom: 0;
    justify-content: space-between;
    padding: 0 50px;
  }

  .mobile-head-section .config-select .config-select-options {
    width: 120px;
  }

  div#mobile-languages {
    margin-bottom: 10px;
  }

  .mobile-links-section {
    border-top: 2px solid #eee;
  }
}

@media (min-width: 992px) and (max-width: 1499px) {
  .service-block h3 {
    font-size: 18px;
    margin-top: 5px;
    margin-bottom: 5px;
  }
}

@media (min-width: 1200px) and (max-width: 1359px) {
  .service-block a {
    padding: 20px;
  }

  .service-img {
    position: absolute;
    left: 0;
    top: 20px;
  }

  .service-block h3 {
    font-size: 22px;
  }

  body .service-block span {
    font-size: 13px;
  }
}

@media (min-width: 1360px) and (max-width: 1439px) {
  .service-block a {
    padding: 20px;
  }

  .service-img {
    position: absolute;
    left: 20px;
    top: 20px;
  }

  .service-block h3 {
    font-size: 22px;
  }
}

@media (min-width: 1440px) and (max-width: 1599px) {
  .service-block h3 {
    font-size: 22px;
  }
}

.services-wrapper {
  display: flex;
  flex-flow: row wrap;
  padding: 0;
  margin-top: 30px;
  justify-content: space-between;
}

.service-block {
  display: flex;
  width: calc(33.33333333333% - 20px);
  background: white;
  margin-bottom: 20px;
  transition: all 0.3s ease-in-out;
  padding: 20px;
}

.service-block a {
  display: flex;
  flex-flow: column;
  align-items: center;
  padding: 0;
  text-align: center;
  text-decoration: none;
}

.service-block img {
  height: 50px;
  max-width: 100%;
}

.service-block h3 {
  font-size: 18px;
  margin-bottom: 10px;
  font-weight: 500;
  transition: all 0.3s ease-in-out;
  color: #333333;
}

.service-block span {
  color: #333333;
  font-size: 15px;
  line-height: 1.7;
  margin-bottom: 20px;
  height: 75px;
  overflow: hidden;
  text-overflow: ellipsis;
}

.service-block i {
  font-size: 50px;
  transition: all 0.3s ease-in-out;
  color: #f44336;
}

.service-block .service-img i {
  color: #1180b0;
}

.service-block:hover {
  background: #f5f5f5;
  border-radius: 6px;
}

.service-block:hover span {
  color: #666 !important;
}

@media (max-width: 900px) {
  .service-block {
    width: calc(50% - 20px);
  }
}

@media (max-width: 600px) {
  .service-block {
    width: 100%;
    padding: 5px;
    margin-bottom: 0;
  }
}

/*rfq*/
.rfqs-list {
  display: block;
  padding: 0;
}

.rfq-block {
  background: #fafafb;
  padding: 20px;
  transition: all 0.3s ease-in-out;
  border: 1px solid #f4f4f4;
  border-radius: 10px;
  display: block;
  flex-direction: column;
  justify-content: center;
  align-content: center;
}

.rfq-block:hover {
  border-color: #1180b0;
}

.rfq-block a {
  display: flex;
  flex-flow: column;
  align-items: flex-start;
  text-decoration: none;
}

.rfq-block .rfq-footer h4 {
  color: #666666;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
}

.rfq-block .rfq-tag {
  background-color: #1180b0;
  color: white;
  font-size: 12px;
  padding: 2px 12px;
  border-radius: 4px;
}

.rfq-block .rfq-title {
  font-size: 16px;
  font-weight: 500;
  color: #333333;
}

.rfq-block .clearfix {
  margin-bottom: 10px;
}

.rfq-block .rfq-country {
  color: #a8a9a8;
  font-size: 17px;
  display: flex;
  align-items: center;
}

.rfq-block img {
  height: auto;
  margin-right: 5px;
  width: 28px !important;
}

.rfq-block .media {
  display: block;
  width: 100%;
}

/*brand logo*/
.home-section.home-section-6 span.btn-white {
  font-size: 12px;
  cursor: auto;
  color: #999;
  letter-spacing: 0.3px;
}

.home-section.home-section-6 span.btn-white {
  font-size: 12px;
  cursor: auto;
  color: #999;
  letter-spacing: 0.3px;
}

.heart {
  animation: heartbeat 1.2s infinite !important;
  display: inline-block;
}

@keyframes heartbeat {
  0% {
    transform: scale(0.75);
  }
  20% {
    transform: scale(1);
  }
  40% {
    transform: scale(0.75);
  }
  60% {
    transform: scale(1);
  }
  80% {
    transform: scale(0.75);
  }
  100% {
    transform: scale(0.75);
  }
}

@media screen and (min-width: 1200px) {
  .home-section.home-section-6 span.btn-white {
    background-color: #fff;
    z-index: 9;
    padding-left: 20px;
  }

  .home-section.home-section-6 span.btn-white {
    background-color: #fff;
    z-index: 9;
    padding-left: 20px;
  }
}

/* Browse By Category */
.product_link {
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  overflow: hidden;
  max-height: 38px;
}

.product_img img {
  border-radius: 6px;
}

.main_category_count {
  font-size: 14px;
  margin-top: 10px;
  color: #666666;
  font-weight: normal;
}

/* Food & Food Processing Machines Marketplace */
.processing_machines_title {
  left: 0;
  top: 0;
  padding: 40px 20px;
  display: flex;
  flex-wrap: wrap;
}

.processing_machines_title a {
  background-color: #fff;
  color: #1180b0;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 50px;
}

.sub_left_top {
  border-radius: 6px 0 0 0;
}

.sub_right_top {
  border-radius: 0 6px 0 0;
}

.sub_left_bottom {
  border-radius: 0 0 0 6px;
}

.sub_right_bottom {
  border-radius: 0 0 6px 0;
}

.processing_machines_title h2 {
  font-size: 20px;
}

.sub-category {
  background: #fff;
  overflow: hidden;
  /* transition: all 0.3s ease-in-out; */
  border-color: #ddd;
  transition: none;
}

.sub_category_title h3 {
  font-size: 16px;
  color: #333333;
}

.sub-category a {
  padding: 10px;
}

.sub-category a img {
  max-height: 100%;
  width : 100px
}

#main-category-content {
  display: flex;
  flex-flow: column;
  align-items: center;
}

.main-category-sub-image {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 264px;
}

.buyer-logo {
  background: white;
  width: 140px;
  height: 130px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px 10px;
  transition: all 0.3s ease-in-out;
  margin: 0 auto;
}

.buyer-logo:hover {
  background: #f5f5f5;
}

.rfqs-list {
  margin-top: 30px;
}

.category_flag_sec .double-sec-heading .sec-heading {
  width: 86%;
}

.home_ad img {
  max-width: 100%;
}

/*footer*/
/* Subscribe Section */
.contact_info_body i {
  color: #dedede;
  font-size: 34px;
}

.contact_info_body a {
  color: #dedede;
  font-size: 13px;
}

.contact_info_body a:hover {
  color: #fff;
}

.newsletter-right-subtitle {
  text-align: left;
  font-size: 14px;
  color: #999;
  margin-left: 0;
  margin-top: 8px;
}

.shopping-cart .dropdown-menu#cart-dropdown a.remove {
  color: #c3c3c3;
}

.newsletter {
  background: #1180b0;
  padding: 30px 0;
}

.newsletter-left {
  position: relative;
}

.newsletter .container {
  padding: 0;
}

.newsletter .container .newsletter_row {
  display: flex;
  align-items: center;
}

.newsletter-right {
  padding-right: 0;
}

.newsletter-right button {
  cursor: pointer;
  background: #1180b0;
  width: 30%;
  color: #fff;
  font-size: 14px;
  font-weight: 700;
  border: none;
  border: 2px solid transparent;
  transition: all 0.3s;
  border-radius: 0 6px 6px 0;
}

.newsletter-right button:hover {
  border-color: #1180b0 !important;
}

.newsletter-right button i {
  margin-top: 10px;
}

.newsletter-rights .newsletter-right-subtitle {
  color: #999;
  font-size: 12px;
  margin-top: 5px;
}

.newsletter-icon svg {
  width: 60px;
  height: 60px;
}

.newsletter-icon svg path {
  fill: white;
}

.newsletter-icon {
  position: absolute;
  top: 0;
  left: 0;
}

.newsletter-text h3 {
  margin: 0;
  font-weight: 500;
  font-size: 22px;
  color: #fff;
  margin-bottom: 15px;
}

.newsletter-text p {
  font-size: 14px;
  color: #dedede;
  max-width: 550px;
  margin: -5px auto 20px;
}

.newsletter-right {
  max-width: 550px;
  margin: 0 auto 55px;
}

.footer_col_12 {
  text-align: center;
}

.newsletter-right form {
  display: flex;
}

.newsletter-right input {
  width: 80%;
  height: 40px;
  padding: 15px;
  border: none;
  font-size: 16px;
  color: #000;
  border-radius: 6px 0 0 6px;
}

.newsletter-right input::placeholder {
}

input:focus {
  outline: 0;
}

.d-flex.start_rate {
  align-items: center;
  justify-content: space-between;
}

@media screen and (max-width: 767px) {
  .services-wrapper {
    margin-top: 0;
  }

  .services-wrapper-top {
    border-bottom: 1px solid #dee2e6;
  }

  input#email-subscription {
    border-radius: 50px;
  }

  .newsletter .container {
    display: block;
  }

  .newsletter-left,
  .newsletter-right {
    padding: 0;
  }

  .newsletter-icon {
    display: none;
  }

  .newsletter-text {
    padding: 0 10px;
    margin-bottom: 30px;
  }

  .newsletter-text h3 {
    font-size: 22px;
    text-align: center;
    margin-bottom: 5px;
  }

  .newsletter-text p {
    font-size: 12px;
  }

  .newsletter-right form {
    display: block;
    position: relative;
  }

  .footer-width.footer-info {
    margin-top: 10px;
  }

  .newsletter-right input,
  .newsletter-right button {
    width: 100%;
  }

  .newsletter-right button {
    height: 100%;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    width: auto;
    padding: 0 12px;
  }

  .newsletter-right input {
    padding-right: 100px;
  }

  .newsletter .container .newsletter_row {
    display: block;
    align-items: center;
    padding: 0 15px;
  }

  .newsletter-right {
    max-width: 100%;
    margin: 0 10px 20px 10px;
  }

  .shopping-cart .dropdown-menu#cart-dropdown .store-link1 {
    font-size: 13px;
    padding: 7px 20px;
  }

  header nav .row.header_row_2 {
    padding: 9px 0;
  }

  .cat_menu_text span {
    font-size: 14px;
  }

  .cat_menu_text i {
    font-size: 16px;
  }

  .links.header-section a,
  .config_select_def span {
    font-size: 10px;
  }

  .config_select_def > span > img {
    width: 19px;
  }

  .select_def_box {
    position: fixed;
    left: 0;
    width: 100%;
  }

  .select_def_box {
    padding: 14px 20px;
  }

  header .header-section.config .config-select {
    width: 60%;
    margin: 0 auto;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .newsletter .container {
    display: block;
  }

  .newsletter-left {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .newsletter-left.col-md-7,
  .newsletter-right.col-md-5 {
    width: 50%;
  }

  .newsletter-icon svg {
    width: 50px;
    height: 50px;
  }

  .newsletter-text {
    padding-left: 60px;
  }

  .newsletter-text h3 {
    font-size: 25px;
  }

  .newsletter-text p {
    font-size: 16px;
  }

  .newsletter-right button {
    width: 30%;
  }
}

@media (min-width: 1200px) and (max-width: 1599px) {
  .newsletter-right button {
    width: 30%;
  }

  .newsletter-right input {
    width: 70%;
  }
}

@media screen and (min-width: 1200px) {
  section.testimonial-sec .home-section-title h5 {
    font-size: 25px;
  }

  .footer-social a {
    margin-right: 0;
    margin-left: 10px;
    width: 25px;
    height: 25px;
    border: 1px solid #63758a;
  }
}

/* Footer */
.footer-width {
  width: 20%;
  float: left;
  padding: 0 15px;
}

.footer-info p {
  font-size: 14px;
  line-height: 1.5;
  margin-top: 25px;
  color: #dedede;
}

.soc_download span,
.play_icon_top {
  color: #dedede;
}

.play_icon_top:hover {
  color: #fff;
}

.play_icon_top i {
  font-size: 24px;
}

.play_icon_top {
  position: relative;
  top: 5px;
}

.footer-link h4,
.footer-contact h4 {
  font-size: 18px;
  color: #ffffff;
  font-weight: 700;
  margin-bottom: 20px;
}

.footer-link ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.footer-link ul li a:hover {
  color: #ffffff;
}

.footer-link ul li a {
  font-size: 13px;
  color: #dedede;
  font-weight: 400;
  padding-bottom: 8px;
  display: inline-block;
  position: relative;
  transition: all 0.4s;
  -webkit-transition: all 0.4s;
  -o-transition: all 0.4s;
  -ms-transition: all 0.4s;
  -moz-transition: all 0.4s;
}

footer {
  background-color: #3d5167;
}

.contact-info a,
.contact-info p {
  font-size: 14px;
  color: #dedede;
  padding-left: 35px;
  line-height: 1.44;
  transition: all 0.3s;
}

.contact-info a:hover {
  color: #fff;
}

.contact-info img {
  width: 20px;
  position: absolute;
  top: 0;
  left: 0;
}

.contact-info {
  position: relative;
  margin-bottom: 20px;
}

.footer-sec.container {
  border-bottom: 1px solid #63758a;
  padding: 30px 0 30px 0;
}

.footer-width.footer-info {
  padding-left: 0;
}

.footer-width.footer-contact {
  padding-right: 0;
}

.copyright-sec {
  background: #3d5167;
  padding-top: 10px;
  padding-bottom: 10px;
}

.copyright-sec .container .col-md-6 {
  padding: 0;
}

.copyright-sec .container {
  display: flex;
  align-items: center;
  padding: 6px 0;
}

.copyright-sec p {
  margin-bottom: 0;
  font-size: 14px;
  color: #dedede;
}

.copyright-sec p a {
  transition: all 0.3s;
  color: #dedede;
}

.copyright-sec p a:hover {
  color: #fff;
}

.payment-img {
  width: 50px;
  margin: 0 3px;
  background: white;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  border-radius: 5px;
}

.payment-img a {
  display: inline-block;
}

.payment-img img {
  max-width: 100%;
  max-height: 100%;
}

.payment-sec {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

@media screen and (max-width: 767px) {
  body .footer-social a {
    width: 30px;
    height: 30px;
    font-size: 12px;
    margin-right: 7px;
  }

  .payment-img {
    width: 40px;
    height: 25px;
  }

  .footer-width {
    width: 100%;
  }

  .footer-info {
    margin-bottom: 30px;
  }

  .footer-link h4,
  .footer-contact h4 {
    margin-bottom: 4px;
    font-size: 16px;
    position: relative;
  }

  .footer-social {
    margin-top: 20px;
  }

  .contact-info {
    margin-bottom: 10px;
  }

  .contact-info a,
  .contact-info p {
    font-size: 14px;
  }

  .footer-link ul li a {
    font-size: 14px;
    padding-bottom: 7px;
  }

  .contact-info {
    margin-top: 20px;
  }

  /*body .footer-link h4:after, body .footer-contact h4:after {
        content: "";
        background-size: 16px;
        background-repeat: no-repeat;
        width: 100%;
        height: 18px;
        position: absolute;
        background-position: right;
        left: 0;
    }*/
  .footer-link h4.active:after,
  .footer-contact h4.active:after {
    content: "\f106";
  }

  .copyright-sec .container {
    display: block;
    padding: 20px 0;
  }

  .copyright-sec p {
    text-align: center;
  }

  .payment-sec {
    margin-top: 10px;
    justify-content: center;
  }

  .footer-width.footer-contact {
    padding-right: 10px;
  }

  .btn-blue {
    padding: 8px 0;
  }

  .footer-width {
    padding: 0 10px;
  }

  .footer-width.footer-info {
    padding-left: 10px;
    text-align: center;
  }

  .footer-width.footer-info > a:first-child img {
    max-width: 160px;
  }

  .footer-link h4,
  .footer-contact h4 {
    font-weight: 500;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-width.footer-info {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .footer-width.footer-contact,
  .footer-width.footer-link {
    width: 100%;
  }

  .footer-link h4:after,
  .footer-contact h4:after {
    content: "\f107";
    font-family: Fontawesome;
    position: absolute;
    right: 0;
  }

  .footer-link h4,
  .footer-contact h4 {
    margin-bottom: 15px;
    font-size: 22px;
    position: relative;
  }

  .copyright-sec .col-md-6 {
    width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1359px) {
  .footer-info p {
    margin-top: 25px;
    max-width: 520px;
    margin: 20px auto;
  }
}

@media (min-width: 1360px) and (max-width: 1439px) {
  .footer-info p {
    font-size: 14px;
  }
}

.footer-social p {
  color: #dedede;
  font-size: 14px;
}

.footer-social a {
  font-size: 16px;
  margin-right: 7px;
  width: 40px;
  height: 40px;
  color: white;
  background: transparent;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  border: 2px solid #63758a;
  transition: all 0.3s;
}

.footer-social a:hover {
  background-color: transparent;
  color: #fff;
  border-color: transparent;
}

.footer-social a.fb:hover {
  background-color: #2b4170; /* fallback color */
  background: -moz-linear-gradient(top, #3b5998, #2b4170);
  background: -ms-linear-gradient(top, #3b5998, #2b4170);
  background: -webkit-linear-gradient(top, #3b5998, #2b4170);
}

.footer-social a.inst:hover {
  background: #f09433;
  background: -moz-linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
  );
  background: -webkit-linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
  );
  background: linear-gradient(
          45deg,
          #f09433 0%,
          #e6683c 25%,
          #dc2743 50%,
          #cc2366 75%,
          #bc1888 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f09433', endColorstr='#bc1888', GradientType=1);
}

.footer-social a.twitter:hover {
  background-color: #00acee;
}

.footer-social a.youtube:hover {
  background-color: #c4302b;
}

.footer-social {
  display: flex;
  align-items: center;
}

.d-inline-block {
  display: inline-block;
}

.service-block p i {
  margin-left: 10px;
  font-size: 18px;
  color: #1180b0;
}

.service-block p {
  display: flex;
  margin-bottom: 0;
  align-items: center;
  color: #1180b0;
  margin-top: 15px;
  letter-spacing: 0.5px;
  font-weight: 500;
}

.service-block span {
  color: #333333;
}

.service-block span {
  font-size: 13px;
}

/*product sec*/
.item-2 {
  display: flex;
  background: white;
  /* transition: all 0.3s ease-in-out; */
  width: 99%;
  margin-left: 2px;

}

.item-2 a {
  display: flex;
  flex-flow: column;
  padding: 20px 20px 20px;
  text-decoration: none;
  width: 100%;
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  transition: none;
}
.item-2 a:hover{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
}

.item-2 .item-2-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 120px;
  margin-bottom: 20px;
  overflow: hidden;
}

.item-2 .item-2-image img {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.3s ease-in-out;
  width: auto !important;
}

.item-2-mq {
  color: #666666;
  font-size: 12px;
  margin-bottom: 5px;
  height: 14px;
}

.item-2-title {
  color: #333333;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 6px;
  height: 36px;
}

.item-2 .item-2-price {
  text-transform: uppercase;
  color: #f44336;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
  height: 15px;
  display: none;
  align-items: center;
  justify-content: center;
  opacity: 0;
}

.item-2 .item-2-rating {
  font-size: 12px;
}

.item-2 .item-2-rating i {
  color: #e1e1e1;
}

.item-2 .item-2-rating i.gold {
  color: #ffc106;
}

/*preoduct_3*/
.item-3 {
  display: flex;
  background: white;
  transition: none;
  /* transition: all 0.3s ease-in-out; */
}

.item-3 {
  padding: 20px 20px 20px;
  border: 1px solid #f4f4f4;
  width: 99%;
  border-radius: 6px;
  width: 99%;
  margin-left: 2px;
}
.item-3:hover{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
}

.item-3 a {
  display: flex;
  flex-flow: column;
  text-decoration: none;
  width: 100%;
  text-align: left;
}

.item-3 .item-3-image {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 140px;
  margin-bottom: 20px;
  overflow: hidden;
}

.item-3 .item-3-image img {
  max-width: 100%;
  max-height: 100%;
  transition: all 0.3s ease-in-out;
  width: auto !important;
  object-fit: cover;
  height: 100%;
}

.item-3 .item-3-title {
  color: #333333;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  height: 44px;
}

.item-3 .item-3-price {
  text-transform: uppercase;
  color: #f44336;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 15px;
  height: 15px;
  display: none;
  justify-content: center;
  align-items: center;
  opacity: 0;
}

.item-3-wrapper .item-3-price {
  margin-bottom: 0;
  margin-top: 0;
}
.old-price {
  color: #171725;
  font-size: 10px;
  margin-right: 3px;
  text-decoration: line-through;
}

.nice-select .list {
  max-height: 150px;
  overflow-y: auto;
}

/*Customized products*/
.customized_products .sec-heading span {
  padding-right: 0;
}

.customized_products .sec-heading:after {
  display: none;
}

.customized_group_img img {
  width: 80px;
}

.customized_group_title p {
  color: #666666;
  line-height: 1.5;
  font-size: 13px;
  height: 63px;
}
.rfq-2-details p{
  color: #666666;
  font-size: 13px;
}

/* Global Stores */
.home-store {
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
  border: 1px solid #f4f4f4;
  border-radius: 6px;
  position: relative;
  margin: 3px;
}
.home-store:hover{
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.25);
  z-index: 999999999999999999999;
}

.home-store:before {
  content: "";
  background: rgb(0 0 0 / 52%);
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

.home-store .store-details {
  position: relative;
  width: 100%;
}

.home-store .store-details .store-country {
  display: flex;
  align-items: center;
  margin-top: 10px;
  color: #333333;
  font-size: 12px;
  font-weight: bold;
}

.home-store .store-details .store-seller-badge {
  position: absolute;
  top: 0;
  right: 25px;
}

.home-store .store-details .store-seller-badge img {
  max-height: 20px;
}

.home-store .store-details .store-quality-icon {
  margin-bottom: 5px;
  display: flex;
  align-items: center;
}

.home-store .store-details .store-quality-icon img {
  max-height: 20px;
  width: auto;
}

.home-store .store-details .store-verified {
  margin: 4px 0;
  text-align: center;
  width: 100%;
}

.home-store .store-details .store-profile-image {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 15px;
}

.home-store .store-details .store-profile-image img {
  max-width: 100%;
  max-height: 75px;
  width: auto;
}

.home-store .store-details .store-country img {
  width: 28px;
  margin-right: 5px;
  border-radius: 4px;
}

.home-store .store-details .store-name {
  color: #117faf;
  margin: 0;
  line-height: 22px;
  text-transform: uppercase;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 7px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 44px;
}

.home-store .store-details .store-name a {
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
}

.home-store .store-details .store-date {
  margin-top: 5px;
  color: #666666;
  font-size: 12px;
}

.home-store .store-links {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  width: 100%;
  position: absolute;
  z-index: 2;
  left: 0;
  top: 50%;
  transform: translateY(-40%);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}

/*.home-store:hover:before{
    opacity: 1;
    visibility: visible;
}
.home-store:hover .store-links{
    transform: translateY(-50%);
    opacity: 1;
    visibility: visible;
}*/
.home-store .store-links a {
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  color: #a5a5a5;
  font-size: 20px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: white;
}

.home-store .store-links a:hover {
  transform: scale(1.1);
}

.home-store .store-links .store-whatsapp {
  background: #1dd742;
  color: white;
  border-color: #1dd742;
  font-size: 13px;
}

.home-store .store-links .store-whatsapp i {
  font-size: 20px;
}

.home-store .store-links .store-link {
  border: 0;
  justify-content: flex-start;
  font-size: 14px;
  background: #1180b0;
  border-radius: 0;
  width: auto;
  margin: 0 auto;
  color: white;
  padding: 0 15px;
  margin-top: 20px;
}

/* Deal Section */
.deal-sec .row .col-md-6:first-child {
  padding-left: 0;
  padding-right: 7px;
}

.deal-sec .row .col-md-6:last-child {
  padding-right: 0;
  padding-left: 7px;
}

.deal-right-sec {
  width: 35%;
  background-position: center;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
}

.deal-right-sec.commoities-sec {
  background-position: right;
}

.deal-left-sec {
  width: 65%;
  padding-left: 18px;
}

.deal-product-body {
  display: flex;
  margin-top: 20px;
}

.deal-left-sec .col-md-6 {
  padding: 0 5px;
}

.deal-left-sec .row {
  margin: 0 -5px;
}

.deal-right-sec ul {
  padding: 0;
  margin-bottom: 0;
}

.deal-right-sec ul li {
  display: inline-block;
  margin: 0 2px;
}

.deal-right-sec ul li:first-child {
  margin-left: 0;
}

.deal-right-sec ul li:last-child {
  margin-right: 0;
}

.deal-right-sec ul li span {
  font-size: 16px;
  line-height: 31px;
  text-align: center;
  font-weight: 700;
  color: #1180b0;
  background-color: #fff;
  width: 35px;
  display: inline-block;
  padding: 0;
  height: 35px;
  line-height: 35px;
}

.deal-right-sec h3 {
  color: #fff;
  font-weight: 700;
  font-size: 20px;
}

.deal-right-sec p {
  font-size: 14px;
  color: #fff;
  margin-top: 16px;
  margin-bottom: 10px;
}

.deal-right-sec a {
  display: inline-block;
  background-color: #fff;
  font-size: 14px;
  font-weight: 600;
  color: #f44336;
  width: fit-content;
  padding: 10px 20px;
  border-radius: 60px;
  margin-top: 20px;
}

.deal-right-sec a:hover {
  background-color: #1180b0;
  color: #fff;
}

/* Desktop */
@media (min-width: 1200px) and (max-width: 1649px) {
  .product_link {
    font-size: 14px;
    display: inline-block;
    overflow: hidden;
    height: 35px;
    margin-top: 3px;
    text-align: center;
    line-height: 14px;
    vertical-align: text-top;
  }

  .customized_group_title p {
    width: 100%;
    margin-top: 9px;
  }

  .service-img {
    top: 0;
  }

  .main_category_count {
    font-size: 12px;
  }

  .processing_machines_title h2 {
    font-size: 16px;
    max-width: 160px;
    line-height: 1.4;
  }

  .sub_category_title h3,
  .processing_machines_title a {
    font-size: 14px;
    font-weight: 100;
  }

  .deal-right-sec {
    padding: 10px;
  }

  .deal-right-sec h3 {
    font-size: 16px;
  }

  .deal-right-sec p {
    font-size: 15px;
  }

  .deal-right-sec ul li span {
    font-size: 14px;
  }

  .deal-right-sec a {
    font-size: 13px;
    padding: 7px 15px;
  }

  .stats-title {
    font-size: 17px;
  }

  .stats-details {
    font-size: 15px;
  }

  .rfq-list .item-info,
  .service-block p {
    font-size: 13px;
  }

  .service-block h3 {
    font-size: 16px;
  }

  .blog-block .blog-block-title {
    font-size: 15px;
    margin-bottom: 10px;
  }

  .blog-block-footer p {
    font-size: 14px;
  }

  .blog-block .blog-block-date {
    font-size: 12px;
  }

  .testimonial-body p {
    font-size: 14px;
  }

  .testimonial-info h3 {
    font-size: 13px;
  }
}

@media (min-width: 1200px) and (max-width: 1649px) {
  .white-box {
    padding: 30px;
  }

  .text_head_box_1 span img {
    width: 25px;
  }

  .menu_list_hover li {
    padding-left: 14px;
    padding-right: 14px;
  }

  .topbanner_text .categories-links .mobile-link {
    padding: 5px 10px;
    margin-top: 0;
  }

  .text_head_box_1 {
    font-size: 10px;
  }

  .topbanner_text_right_col .home-side-banner .home-side-banner-details {
    padding: 19px 40px 16px 10px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-title {
    font-size: 15px;
    margin-bottom: 12px;
  }

  .menu_list_hover li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .hot_label {
    font-size: 8px;
  }

  .header_col2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .header_col3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sec-heading span {
    padding-right: 18px;
  }

  .btn-white,
  .item-3 .item-3-title1 {
    font-size: 12px;
  }

  .double-sec-heading .btn-white i {
    font-size: 11px;
    padding-left: 4px;
  }

  .rfq-block .rfq-title {
    font-size: 14px;
  }

  .rfq-block .rfq-footer h4 {
    font-size: 12px;
  }

  .rfq-block img {
    margin-right: 0;
    width: 20px !important;
  }

  .rfq-block .clearfix {
    margin-bottom: 8px;
  }

  .rfqs-list {
    margin-top: 20px;
  }

  .white-box {
    padding: 20px;
  }

  .item-2 a,
  .item-3 {
    padding: 10px;
  }

  .item-2 .item-2-title {
    font-size: 14px;
  }
  .store-name a{
    font-weight: 500;
    font-size: 14px;
  }

  .item-2 .item-2-price,
  .item-3 .item-3-price {
    font-size: 11px;
  }

  .item-2 .item-2-mq {
    font-size: 10px;
  }

  .item-3 .item-3-title {
    margin-bottom: 0;
  }

  .stats-inner {
    padding: 35px 20px 30px 20px;
  }

  .sec-heading span {
    font-size: 16px;
  }

  .double-sec-heading p {
    font-size: 9px;
  }

  .menu_list_hover li {
    padding-left: 14px;
    padding-right: 14px;
  }

  div .col-55 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .right_menu_responsive {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 47.666667%;
    flex: 0 0 44.666667%;
    max-width: 44.666667%;
  }

  .cart-icon-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 35.666667%;
    flex: 38.666667%;
    max-width: 38.666667%;
  }

  .right_menu_responsive_1 {
    max-width: 60%;
    flex: 0 0 60%;
  }

  .cart-icon-right_1 {
    max-width: 23.32%;
    flex: 0 0 23.32%;
  }

  .browse_form_col_1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .fast_sourcing_form .btn-blue {
    font-size: 11px;
  }

  .form_one {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .form_one_1 {
    flex-grow: 1.8;
  }

  .footer-info p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .soc_download {
    margin-top: 10px;
  }

  .footer-link h4,
  .footer-contact h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .footer-link ul li a {
    padding-bottom: 5px;
    font-size: 12px;
  }

  .footer-social p,
  .footer-social a,
  .contact-info a,
  .contact-info p {
    font-size: 12px;
  }

  .footer-social a {
    margin-left: 5px;
    width: 30px;
    height: 30px;
  }

  .payment-img {
    height: 25px;
    width: 40px;
  }

  .home_slider .owl-stage-outer a {
    display: block;
    height: 238px;
  }
}
@media screen and (max-width: 768px) {
  .topbanner_text.topbanner_banner {
    margin-top: 44px;
  }
}
.topbanner_text.topbanner_banner {
  z-index: 9;
  position: relative;
}

/*home responsive*/
@media screen and (min-width: 768px) {
  .sub-category a {
    padding: 10px;
    height: 130px;
  }
}

@media screen and (max-width: 767px) {
  body {
    background-color: #eff0f5;
  }

  .sec-heading span,
  .form_one_1 {
    padding-right: 0;
    font-size: 18px;
    font-weight: 500;
    color: #333333;
  }

  .main_slider_responsive,
  .form_one,
  .topbanner_text_right_col,
  .deal-left-sec,
  .deal-sec .row .col-md-6:last-child,
  .stats-sec .row .col-md-6:last-child {
    padding-left: 0;
  }

  .white-box {
    padding: 0 15px;
  }

  .white-box {
    box-shadow: none;
  }

  .white-box,
  .home_slider .item,
  .home_slider .owl-stage-outer,
  .home_slider .owl-stage-outer a {
    border-radius: 0;
    display: block;
  }

  .browse_category_top {
    /*background: #f2f3f7;*/
    padding: 0;
  }

  .category_flag_sec {
    background: white;
    padding: 10px 15px;
    margin: 20px 0;
  }

  .sec-heading {
    font-size: 17px;
  }

  .category_flag_sec h2.sec-heading {
    font-size: 15px;
  }

  .logo_wrap img {
    max-width: 100%;
  }

  .rfq-2-form-elements input {
    height: 40px;
    font-size: 11px;
    padding: 0 7px;
  }

  .fast_sourcing_form .btn-blue {
    font-size: 14px;
    width: 100%;
    padding: 6px 0;
  }

  .main_slider_responsive .category_flag_sec .fast_sourcing_form .btn-blue {
    font-size: 12px;
    padding: 10px 0;
  }

  header nav .row {
    margin: 0;
  }

  .topbanner_text_right_col .home-side-banner .home-side-banner-details {
    padding: 55px 40px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-link {
    margin-bottom: 0;
  }

  .main_cat {
    flex-wrap: wrap;
    margin-left: 10px;
    margin-right: 10px;
    justify-content: center;
  }

  .topbanner_text_right_col .home-side-banner {
    width: 100%;
    flex: auto;
    margin: 0;
    height: 150px;
  }

  .topbanner_text_right_col .home-side-banner:nth-child(2) {
    margin: 0;
  }

  .home-side-banner1 {
    margin-right: 5px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-title {
    font-size: 18px;
    margin-bottom: 13px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-link
  a {
    font-size: 12px;
    height: 30px;
    width: 100px;
    justify-content: center;
  }

  .double-sec-heading a {
    font-size: 0;
  }

  .sec-heading:after {
    display: none;
  }

  .sec-heading > img {
    width: 20px;
  }

  .product_link {
    line-height: 1.3;
    font-size: 14px;
    display: block;
    margin-top: 5px;
  }

  .main_category_count {
    font-size: 10px;
    margin-top: 3px;
  }

  .category_flag_sec .row {
    margin: 0;
  }

  .main_slider {
    overflow: inherit;
    z-index: 9999;
    position: relative;
  }

  .col-md-3.form_one {
    z-index: 9;
  }

  .form_one.form_one_1 {
    padding-right: 0;
  }

  .category_flag_sec .row.rfq-2-form-elements {
    margin: 0 -6px;
  }

  .home_slider {
    overflow: hidden;
  }

  .home_slider .owl-nav button {
    width: 18px;
    height: 40px;
    font-size: 16px;
  }

  .categories_sub_div .sub-categories-list {
    padding: 0;
  }

  .sub_category_title h3 {
    font-size: 12px;
  }

  .sub_left_top,
  .sub_right_top,
  .sub_right_bottom,
  .sub_left_bottom {
    border-radius: 0;
    flex: 0 0 47%;
    max-width: 47%;
  }

  .item-2 {
    margin-left: 0;
  }

  .item-2 a,
  .item-3 {
    padding: 10px;
  }

  .item-2-mq {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .item-2 .item-2-title,
  .item-3 .item-3-title {
    font-size: 12px;
    margin-bottom: 5px;
  }

  .item-2 .item-2-price,
  .item-3-wrapper .item-3-price {
    font-size: 10px;
    margin-bottom: 0;
  }

  .item-2 .item-2-rating i {
    font-size: 10px;
  }

  .request_form.request_form_2 {
    padding: 0 15px;
  }

  .item-3 {
    margin-left: 0;
  }

  .item-3 .item-3-title {
    margin-bottom: 0;
    height: 35px;
  }

  .rfqs-list {
    margin-top: 0.5rem;
  }

  .rfq-block {
    padding: 10px 25px;
  }

  .rfq-block .rfq-title {
    font-size: 14px;
  }

  .rfq-block .rfq-tag {
    font-size: 9px;
    padding: 1px 8px;
  }

  .rfq-block img {
    height: 14px;
    width: 20px !important;
    margin-right: 3px;
  }

  .rfq-block .rfq-footer h4 {
    font-size: 12px;
  }

  .rfq-block .clearfix {
    margin-bottom: 5px;
  }

  .deal-right-sec,
  .deal-left-sec {
    width: 100%;
  }

  .deal-product-body {
    display: block;
    margin-top: 0.5rem;
  }

  .deal-right-sec {
    padding: 10px;
  }

  .deal-right-sec p {
    font-size: 14px;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .deal-right-sec ul li span {
    font-size: 12px;
    width: 25px;
    height: 25px;
    line-height: 25px;
  }

  .deal-right-sec a {
    font-size: 12px;
    padding: 6px 10px;
    margin-top: 10px;
  }

  .deal-product-body .deal-left-sec .space_remove_responsive {
    padding: 0 5px;
  }

  .deal-right-sec h3 {
    font-size: 14px;
  }

  .customized_group_title p {
    font-size: 10px;
  }

  .topbanner_banner .owl-dots {
    bottom: 5px;
  }

  .topbanner_banner .owl-dots button {
    width: 9px;
    height: 9px;
  }
}

@media (min-width: 768px) and (max-width: 1199px) {
  .item-3 .item-3-title {
    min-height: 49px;
  }

  .main_slider_responsive {
    padding-left: 0;
  }

  .white-box {
    padding: 20px;
  }

  .home_slider .item,
  .home_slider .owl-stage-outer,
  .home_slider .owl-stage-outer a {
    display: block;
  }

  .fast_sourcing_form .btn-blue {
    font-size: 12px;
  }

  .rfq-2-form-elements input,
  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-title {
    font-size: 12px;
  }

  .topbanner_text_right_col .home-side-banner .home-side-banner-details {
    padding: 17.7px 10px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-link {
    margin-bottom: 0;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-link
  a {
    font-size: 9px;
    padding: 0 10px;
    height: 25px;
    width: 75px;
  }

  .category_flag_sec .double-sec-heading .sec-heading {
    width: 75%;
  }

  .btn-white,
  .main_category_count {
    font-size: 12px;
  }

  .sec-heading span {
    font-size: 20px;
  }

  .product_link {
    font-size: 14px;
    display: block;
  }

  .browse_category_top .produc_content {
    margin-top: 5px;
  }

  .sub-category .sub_category_image img {
    max-width: 60px;
  }

  .sub_right_top,
  .sub_left_top,
  .sub-category {
    border-radius: 0;
  }

  .sub_category_title h3 {
    font-size: 12px;
  }

  .item-2 a,
  .item-3 {
    padding: 10px;
  }

  .item-2 .item-2-title,
  .item-3 .item-3-title {
    font-size: 12px;
  }

  .item-2-mq {
    font-size: 10px;
    margin-bottom: 2px;
  }

  .item-2 .item-2-price,
  .item-3-wrapper .item-3-price {
    margin-bottom: 0;
  }

  .item-2 .item-2-price,
  .item-3-wrapper .item-3-price,
  .item-2 .item-2-rating i {
    font-size: 10px;
  }

  .rfq-block .rfq-title {
    font-size: 16px;
  }

  .rfq-block img {
    width: 28px !important;
    height: 16px;
  }

  .rfq-block .rfq-footer h4 {
    font-size: 14px;
  }

  .rfq-block {
    padding: 15px;
  }

  .rfqs-list {
    margin-top: 1rem;
  }

  .deal-product-body {
    display: block;
  }

  .deal-right-sec {
    width: 100%;
  }

  .deal-right-sec h3 {
    font-size: 18px;
  }

  .deal-right-sec a {
    padding: 7px 15px;
    margin-top: 15px;
    font-size: 12px;
  }

  .deal-right-sec p {
    font-size: 12px;
    margin-top: 0;
    margin-bottom: 5px;
  }

  .deal-right-sec ul li span {
    height: 25px;
    line-height: 25px;
    width: 25px;
    font-size: 12px;
  }

  .deal-product-body {
    margin-top: 0.5rem;
  }

  .deal-left-sec {
    width: 100%;
    padding-left: 0;
  }

  .space_remove_responsive {
    padding: 0 5px;
  }

  .customized_group_title p {
    font-size: 12px;
    width: 100%;
  }

  .logo_wrap img {
    max-width: 120px;
  }

  .search_position input {
    height: 33px;
    padding: 0 100px 0 120px;
  }

  .search_position input {
    height: 33px;
    padding: 0 100px 0 120px;
  }

  .header-seach .nice-select {
    line-height: 32px;
    width: 90px;
  }

  .header-seach .nice-select,
  .search_cus_select {
    height: 100%;
  }

  .search_cus_select span.current {
    font-size: 10px;
  }

  .search_cus_select .nice-select:after {
    font-size: 16px;
  }

  .submit_btn_header button {
    padding: 0 7px;
    font-size: 12px;
  }

  .submit_btn_header i {
    font-size: 12px;
  }

  .menu_list_hover {
    flex-wrap: nowrap;
    flex-flow: row;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  /*.main_slider_responsive{
        padding-left: 0;
    }*/
  .processing_machines_title {
    padding: 15px 20px;
  }

  .processing_machines_title h2 {
    font-size: 16px;
  }

  .topbanner_text_right_col .home-side-banner .home-side-banner-details {
    padding: 31px 10px;
  }

  .deal-right-sec {
    position: relative;
  }

  .deal-right-sec a {
    position: absolute;
    right: 20px;
    top: 0;
  }

  .deal-right-sec.commoities-sec1 a {
    position: relative;
    right: 0;
  }

  .white-box.deal-product {
    height: 100%;
  }

  .border_input2 {
    padding-right: 4px;
  }
}

@media screen and (max-width: 767px) {
  .container.header_container {
    padding: 0 10px;
  }

  .header-seach .nice-select {
    padding: 0 15px 0 10px;
    line-height: 35px;
    width: 95px;
    font-size: 12px;
  }

  .search_cus_select .nice-select:after {
    font-size: 18px;
  }

  .search_position input {
    height: 35px;
    padding: 0 43px 0 100px;
    font-size: 12px;
  }

  .submit_btn_header button {
    padding: 0 10px;
  }

  .header_search_form {
    margin-top: 15px;
  }

  .categories-links .mobile-link {
    display: block;
  }

  .side-nav .category-submenu {
    position: relative;
    top: 0;
    left: 0;
    max-width: 100%;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    -moz-border-radius: 0;
    -webkit-border-radius: 0;
    width: 100%;
    padding: 0;
    border: 0;
    display: none;
    visibility: visible;
    transition: none;
    opacity: 1;
  }

  .side-nav .mobile-link.category-submenu {
    display: flex;
    visibility: visible;
    opacity: 1;
  }

  .side-nav .submenu-section {
    padding: 0 5px;
    width: 100%;
  }

  .side-nav .submenu-section-title a {
    font-weight: bold;
    font-size: 13px;
  }

  .side-nav .submenu-section-link a {
    font-size: 12px;
  }
}

/*cus responsive css*/
@media (min-width: 992px) and (max-width: 1599px) {
  .blog-block .blog-block-title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: auto;
    margin-bottom: 20px;
  }

  .blog-block .blog-block-details {
    padding: 30px 20px;
  }
}

@media screen and (max-width: 767px) {
  .stats-sec .row .col-md-6:last-child {
    padding-left: 0;
    margin-top: 20px;
  }

  .stats-sec .row .col-md-6:first-child {
    padding-right: 0;
  }

  .rfq-list {
    margin: 15px 0;
  }

  .border_input2,
  .request_form .nice-select {
    width: 100%;
  }

  .home-section .stats-inner.home-section-title {
    padding-bottom: 15px;
  }

  .home-section .stats-inner.home-section-title,
  .stats-inner:before {
    border-radius: 0;
  }

  .stats-block {
    width: 50%;
    padding: 0;
    border: none;
  }

  .stats-inner .row {
    margin: 0;
  }

  .stats-title {
    font-size: 17px;
  }

  .stats-details {
    font-size: 13px;
  }

  .stats-inner .col-md-12 {
    padding: 0;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .border_input2,
  .request_form .nice-select {
    width: 100%;
  }

  .white-box,
  .stats-inner {
    padding: 20px;
  }

  .stats-block {
    max-width: 50%;
  }

  .stats-title {
    font-size: 18px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .stats-block {
    max-width: 50%;
  }

  .stats-title {
    font-size: 19px;
  }

  .request_form .nice-select,
  .border_input2 {
    width: 50%;
  }

  .request_form .nice-select {
    padding-left: 4px;
    margin-left: 0;
  }

  .border_input2 {
    padding-right: 4px;
  }

  .stats-block {
    max-width: 50%;
    min-width: 50%;
  }

  .stats-inner {
    padding: 25px 30px 25px 30px;
  }

  .footer-social a {
  }
}

@media screen and (max-width: 767px) {
  .service-block {
    width: 100%;
    padding: 0;
    margin-bottom: 10px;
    margin-top: 10px;
  }

  .service-info {
    /* padding-left: 55px; */
  }

  .service-block:hover {
    background: none;
  }

  .owl-carousel .owl-item .service-block img {
    width: 40px;
  }

  .services-wrapper.owl-carousel button.owl-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    background: #313131;
    opacity: 0.5;
    border: none;
  }

  .services-wrapper.owl-carousel button.owl-dot.active {
    opacity: 1;
  }

  .services-wrapper.owl-carousel .owl-dots {
    text-align: center;
  }

  .service-block h3 {
    font-size: 16px;
    margin-bottom: 4px;
  }

  .service-block p {
    font-size: 14px;
  }

  .double-sec-heading .sec-heading {
    width: 100%;
  }

  .stats-inner:before {
    background: transparent linear-gradient(90deg, #000000b2 0%, #00000000 220%);
  }

  .stats-sec a.btn-white {
    margin-top: 20px;
  }

  .rfq-list .item-info {
    font-size: 12px;
  }

  .premimum_buyers_sec .double-sec-heading {
    display: block;
  }

  .rfq-2 .rfq-2-details .btn-blue {
    font-size: 14px;
    padding: 6px 0;
  }

  .premimum_buyers_sec .double-sec-heading p {
    padding: 5px 0 0 0;
  }

  .service-block span {
    font-size: 12px;
  }

  .service-block p {
    margin-top: 0;
  }

  .service-img {
    max-width: 40px;
  }

  .blog-wrapper.blog-slider {
    margin-top: 0.5rem;
    padding: 0;
  }

  body .blog-block .blog-block-details {
    padding-top: 10px;
    margin-bottom: 3px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .owl-carousel .owl-item .service-block img {
    width: auto;
  }

  .services-wrapper.owl-carousel button.owl-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    padding: 0;
    margin: 0 5px;
    background: #313131;
    opacity: 0.5;
    border: none;
  }

  .services-wrapper.owl-carousel button.owl-dot.active {
    opacity: 1;
  }

  .services-wrapper.owl-carousel .owl-dots {
    text-align: center;
  }

  .service-block {
    padding: 20px 10px;
    margin-top: 0;
    margin-bottom: 0;
  }

  .service-block span {
    font-size: 12px;
  }

  .blog-block .blog-block-title {
    font-size: 14px;
  }
}

@media screen and (max-width: 767px) {
  .buyer-logo {
    height: 100px;
    padding-top: 0;
    width: 110px;
  }

  .premimum_buyers_sec > div.double-sec-heading {
    border-top: 1px solid #dee2e6;
    padding-top: 0.7rem;
  }

  .premimum_buyers_sec > div.buyers-wrapper {
    padding-bottom: 0.3rem;
    border-bottom: 1px solid #dee2e6;
  }
}

@media screen and (max-width: 767px) {
  input#email-subscription {
    border-radius: 50px;
  }

  .newsletter .container {
    display: block;
  }

  .newsletter-left,
  .newsletter-right {
    padding: 0;
  }

  .newsletter-icon {
    display: none;
  }

  .newsletter-text {
    padding: 0 10px;
    margin-bottom: 20px;
  }

  .newsletter-right-subtitle {
    font-size: 12px;
  }

  .newsletter-text h3 {
    font-size: 20px;
    text-align: center;
    margin-bottom: 10px;
  }

  .newsletter-text p {
    font-size: 12px;
  }

  .newsletter-right form {
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .footer-width.footer-info {
    margin-top: 10px;
  }

  .newsletter-right input,
  .newsletter-right button {
    width: 100%;
  }

  .newsletter-right button {
    height: 100%;
    margin-top: 0;
    position: absolute;
    right: 0;
    top: 0;
    font-size: 12px;
    width: auto;
    padding: 0 12px;
    border-radius: 0 50px 50px 0;
    /* height: 100%; */
  }

  .soc_download.mt-3 {
    margin-top: 5px;
  }

  .newsletter-right input {
    padding-right: 100px;
    font-size: 14px;
  }

  .newsletter .container .newsletter_row {
    display: block;
    align-items: center;
    padding: 0 15px;
  }

  .newsletter-right {
    max-width: 100%;
    margin: 0 10px 20px 10px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .newsletter .container {
    display: block;
  }

  .newsletter-left {
    margin-bottom: 30px;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .newsletter-left.col-md-7,
  .newsletter-right.col-md-5 {
    width: 50%;
  }

  .newsletter-icon svg {
    width: 50px;
    height: 50px;
  }

  .newsletter-text {
    padding-left: 60px;
  }

  .newsletter-text h3 {
    font-size: 25px;
  }

  .newsletter-text p {
    font-size: 16px;
  }

  .newsletter-right button {
    width: 30%;
  }
}

@media screen and (max-width: 767px) {
  .footer-width {
    width: 100%;
  }

  .footer-info {
    margin-bottom: 30px;
  }

  .footer-link h4,
  .footer-contact h4 {
    margin-bottom: 4px;
    font-size: 16px;
    position: relative;
  }

  .footer-social {
    margin-top: 20px;
    justify-content: center;
  }

  .contact-info {
    margin-bottom: 10px;
  }

  .contact-info a,
  .contact-info p {
    font-size: 14px;
  }

  .footer-link ul li a {
    font-size: 14px;
    padding-bottom: 7px;
  }

  .contact-info {
    margin-top: 20px;
  }

  .testimonial-body:before {
    width: 100%;
  }

  .testimonial-slider .testimonial-img {
    padding-right: 0;
  }

  .testimonial-info {
    padding-left: 0;
  }

  .footer-link h4:after,
  .footer-contact h4:after {
    content: "\f107";
    right: 5px;
    top: 0;
    color: #fff;
    font-family: Fontawesome;
    font-size: 18px;
    position: absolute;
  }

  .copyright-sec .container {
    display: block;
    padding: 0;
  }

  .copyright-sec p {
    text-align: center;
    font-size: 12px;
  }

  .payment-sec {
    margin-top: 10px;
    justify-content: center;
  }

  .footer-width.footer-contact {
    padding-right: 10px;
  }

  .btn-blue {
    padding: 8px 0;
  }

  .footer-width {
    padding: 0 10px;
  }

  .footer-width.footer-info {
    padding-left: 10px;
    text-align: center;
  }

  .footer-width.footer-info > a:first-child img {
    max-width: 160px;
  }

  .footer-link h4,
  .footer-contact h4 {
    font-weight: 500;
    margin-bottom: 15px;
  }

  .footer-link ul {
    margin-bottom: 25px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .footer-width.footer-info {
    width: 100%;
    text-align: center;
    margin-bottom: 30px;
  }

  .footer-width.footer-contact,
  .footer-width.footer-link {
    width: 100%;
  }

  .footer-width.footer-contact {
    padding-right: 15px;
  }

  .footer-link h4:after,
  .footer-contact h4:after {
    content: "\f107";
    font-family: Fontawesome;
    position: absolute;
    right: 0;
  }

  .footer-link h4,
  .footer-contact h4 {
    margin-bottom: 15px;
    font-size: 20px;
    position: relative;
    font-weight: 600;
  }

  .copyright-sec .col-md-6 {
    width: 50%;
  }

  .stats-block {
    max-width: 50%;
    min-width: 50%;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .footer-social {
    display: inline-block;
    text-align: center;
  }

  .footer-social a {
    display: inline-block;
    text-align: center;
    line-height: 31px;
    width: 34px;
    height: 34px;
    font-size: 13px;
  }

  .footer-social p {
    margin-bottom: 10px;
  }

  .item-3,
  .item-2 {
    margin-left: 0;
  }
}

@media (min-width: 992px) and (max-width: 1359px) {
  .footer-info p {
    margin-top: 25px;
    max-width: 520px;
    margin: 20px auto;
  }
}

@media (min-width: 1200px) and (max-width: 1649px) {
  .white-box {
    padding: 30px;
  }

  .text_head_box_1 span img {
    width: 25px;
  }

  .menu_list_hover li {
    padding-left: 14px;
    padding-right: 14px;
  }

  .topbanner_text .categories-links .mobile-link {
    padding: 5px 10px 5px 0px;
    margin-top: 0;
  }

  .text_head_box_1 {
    font-size: 10px;
  }

  .topbanner_text_right_col .home-side-banner .home-side-banner-details {
    padding: 19px 40px 16px 10px;
  }

  .topbanner_text_right_col
  .home-side-banner
  .home-side-banner-details
  .home-side-banner-title {
    font-size: 15px;
    margin-bottom: 12px;
  }

  .menu_list_hover li {
    padding-left: 12px;
    padding-right: 12px;
  }

  .hot_label {
    font-size: 8px;
  }

  .header_col2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .header_col3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sec-heading span {
    padding-right: 20px;
  }

  .btn-white,
  .item-3 .item-3-title1 {
    font-size: 12px;
  }

  .double-sec-heading .btn-white i {
    font-size: 11px;
    padding-left: 4px;
  }

  .rfq-block .rfq-title {
    font-size: 14px;
  }

  .rfq-block .rfq-footer h4 {
    font-size: 12px;
  }

  .rfq-block img {
    margin-right: 0;
    width: 20px !important;
  }

  .rfq-block .clearfix {
    margin-bottom: 8px;
  }

  .rfqs-list {
    margin-top: 20px;
  }

  .white-box {
    padding: 20px;
  }

  .item-2 a,
  .item-3 {
    padding: 10px;
    margin :2px;
  }

  .item-2 .item-2-title {
    font-size: 13px;
  }

  .item-2 .item-2-price,
  .item-3 .item-3-price {
    font-size: 11px;
  }

  .item-2 .item-2-mq {
    font-size: 12px;
  }

  .item-3 .item-3-title {
    margin-bottom: 0;
  }

  .stats-inner {
    padding: 35px 20px 30px 20px;
  }

  .sec-heading span {
    font-size: 18px;
    font-weight: 500;
  }

  .double-sec-heading p {
    font-size: 9px;
  }

  .menu_list_hover li {
    padding-left: 14px;
    padding-right: 14px;
  }

  div .col-55 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .right_menu_responsive {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 44.666667%;
    max-width: 44.666667%;
  }

  .cart-icon-right {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 38.666667%;
    max-width: 38.666667%;
  }

  .right_menu_responsive_1 {
    max-width: 60%;
    flex: 0 0 60%;
  }

  .cart-icon-right_1 {
    max-width: 23.32%;
    flex: 0 0 23.32%;
  }

  .browse_form_col_1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .fast_sourcing_form .btn-blue {
    font-size: 12px;
  }

  .form_one {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -webkit-box-flex: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    max-width: 100%;
  }

  .form_one_1 {
    flex-grow: 1.8;
  }

  .footer-info p {
    font-size: 12px;
    margin-bottom: 0;
  }

  .soc_download {
    margin-top: 10px;
  }

  .footer-link h4,
  .footer-contact h4 {
    font-size: 18px;
    margin-bottom: 15px;
  }

  .footer-link ul li a {
    padding-bottom: 5px;
    font-size: 12px;
  }

  .footer-social p,
  .footer-social a,
  .contact-info a,
  .contact-info p {
    font-size: 12px;
  }

  .footer-social a {
    margin-left: 5px;
    width: 25px;
    height: 25px;
  }

  .payment-img {
    height: 25px;
    width: 40px;
  }

  .home_slider .owl-stage-outer a {
    display: block;
    height: 238px;
  }
}

/*home responsive*/

/* Blog Responsive */
@media screen and (max-width: 991px) {
  .blog-block .blog-block-title {
    font-size: 14px;
    height: 40px;
    margin-bottom: 10px;
  }

  .blog-block .blog-block-date {
    font-size: 12px;
  }

  .blog-block-footer p {
    font-size: 14px;
  }

  .blog-block .blog-block-details {
    padding: 0;
    padding-top: 20px;
  }

  .menu_list_hover {
    display: block;
    flex-wrap: wrap;
    flex-flow: row;
  }

  .menu_list_hover li {
    width: 100%;
  }

  .side-nav .menu_list_hover li {
    padding: 8px 15px;
    border-bottom: 1px solid #e2e2e2;
  }

  .hot_label {
    display: none;
  }

  .right_menu .menu_title_mobile {
    padding: 0 10px;
    border-bottom: 1px solid #e2e2e2;
    margin-top: 20px;
    font-weight: bold;
    font-size: 18px;
    padding-bottom: 10px;
    text-align: left;
    direction: ltr;
  }

  .right_menu_responsive .right_menu {
    display: none;
  }

  .side-nav .menu_list_hover li {
    text-align: left;
  }
}

@media screen and (min-width: 1200px) {
  .footer-social a {
    margin-right: 0;
  }

  .side-nav .right_menu {
    display: none;
  }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .side-nav .right_menu {
    display: none;
  }

  .cart-icon-right_1 {
    display: none;
  }

  #mobile-languages.config-select i,
  #mobile-currencies.config-select i {
    height: auto;
    width: auto;
    right: -10px;
    top: -5px;
  }

  .side-nav .right_menu {
    display: none;
  }
}

.overlay {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.7);
  z-index: 9999;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  visibility: hidden;
  top: 0;
}

body.active .overlay {
  opacity: 1;
  visibility: visible;
}

.suggest-items-box {
  position: absolute;
  background: white;
  width: 100%;
  box-shadow: 0 0 8px 4px rgba(0, 0, 0, 0.1);
  top: 43px;
  z-index: 1;
}

.suggest-items-box ul {
  padding: 8px 0;
  margin: 0;
}

.suggest-items-box ul li {
  padding: 8px 15px 3px 15px;
  display: flex;
}

.suggest-items-box ul li a {
  width: 100%;
  border-bottom: 1px solid #f2f3f7;
  padding-bottom: 5px;
}

.rfq-2-form-elements input::-webkit-input-placeholder,
.search_position input::-webkit-input-placeholder {
  /* WebKit, Blink, Edge */
}

.rfq-2-form-elements input:-moz-placeholder,
.search_position input:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
}

.rfq-2-form-elements input::-moz-placeholder,
.search_position input::-moz-placeholder {
  /* Mozilla Firefox 19+ */
}

.rfq-2-form-elements input:-ms-input-placeholder,
.search_position input:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
}

.rfq-2-form-elements input::-ms-input-placeholde,
.search_position input::-ms-input-placeholder {
  /* Microsoft Edge */
}

.rfq-2-form-elements input::placeholder,
.search_position input::placeholder {
  /* Most modern browsers support this now. */
}

@media screen and (min-width: 1650px) {
  .fast_sourcing_form .btn-blue {
    font-size: 11px;
  }
}
/* *{
    outline : 1px solid #ddd;
} */

/* @media screen and (min-width: 1650px) {
    .fast_sourcing_form .btn-blue {
        font-size: 11px;
    }
}

@media screen and (min-width: 1200px)  {
    .sub-category{
        max-width: 24.5%;
    }
}
@media screen and (min-width: 992px)  {
    .sub-category{
        max-width: 24.4%;
    }
}
@media screen and (max-width: 992px)  {
    .sub-category{
        max-width: 49%;
    }
}  */

.sub-category.border{
  border-radius: 0px;
}

.hot_label.height{
  line-height: 1.4;
}

.quoteNow{
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding: 20px !important;
}
.custom-padding{
  padding: 20px !important;
}

.filter_main img {
  width: 100%;
}

.select_width{
  width: 200px;
}
.nice-select.select_width::after{
  right: 7px;
}

@media (max-width: 1649px) and (min-width: 1200px){
  .side-nav .submenu-section-link a {
    font-size: 12px;
  }
}

@media (min-width: 1260px){

  .header-icon_container{
    margin-left: 55px;
  }

}

.header-icon_container{
  padding: 0;
}


.header-icon_container li{
  margin-right: 10px;
}
.header-icon{
  width: 22px;
}



.header-icon.icon1{
  width: 30px;
}
@media (max-width: 768px){

  .header-icon.icon1{
    width: 22px !important;
  }

}
.icon-text{
  font-size: 12px;
}
.header-upper{
  padding: 20px 30px !important;
}
@media (min-width: 1260px){

  .header-upper{
    padding: 20px 30px !important;
  }

}
@media (max-width: 768px){

  .header-upper{
    padding: 20px 7px !important;
  }

}



.header-lower{
  padding: 7px 30px !important;
  box-shadow: 2px 2px 3px rgba(0,0,0,.1);
}
.signInText{
  margin-top: 7px;
}
.categories-links .mobile-link{
  transition:all ease  0.3s;
}

.categories-links .mobile-link:hover i{
  transition:all ease  0.3s;
  transform: translateX(6px);
}
.side-nav .mobile-links-section .categories-links .mobile-link{
  transition:all ease  0.3s;
}

.side-nav .mobile-links-section .categories-links .mobile-link:hover .text_head_box_1 a{
  color: #f44336;
}
.side-nav .mobile-links-section .categories-links .mobile-link:hover .text_head_box_2{

  transition:all ease  0.3s;
  transform: translateX(6px);
}
.side-nav .mobile-links-section .categories-links .mobile-link:hover .text_head_box_2 i{

  color: #f44336;
}


.cat_border{
  border: 1px solid #f4f4f4;
  border-radius: 0px;
}
.cat_border:nth-child(1),.cat_border:nth-child(2),.cat_border:nth-child(3),
.cat_border:nth-child(5),.cat_border:nth-child(6){
  border-right:none;
}

@media (max-width: 992px){

  .cat_border:nth-child(2),.cat_border:nth-child(6){
    border-right: 1px solid #f4f4f4;
  }

}


.rfq-2-form-elements .nice-select.open .list{
  width: 170px;
}
.link{
  cursor: pointer;
}

a{
  color: #333333;
}
.side-nav-item{
  font-size: 14px;
}
.reqQuoteHeading{
  font-size: 26px;
  color: #333333;
}

input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #666666;
  font-size: 14px;
}
.headerCur.select_width{
  width: 247px;
}
.sub-category:hover{
  box-shadow: 0px 1px 8px 0px rgba(0,0,0,.25);
  z-index: 9;
}

.main_select{
  display: flex;
  align-items: center;
  height: 45px;
}
.font-light-grey{
  color: #666666;
}
.highlights-list{
  margin-right: 10px;
  color: #0f80b0;
  font-size: 12px;
}
.highlights-list span{
  color: #0f80b0;
}
.bg-theme{
  background: #fafafb;
}
#hospitality_id .left_content_category{
  padding: 20px;
  /* padding-top: 0; */
}

.request_quotation_form{
  background-color: #fafafa;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px ;
  border-bottom: 1px solid #eee;
}


.tranding_brand_side{
  background-color: #fafafa;
  box-shadow: none;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}
.highlights-list{
  color: #1180b0 !important;
}
.highlights-list span{
  color: #1180b0 !important;
}
.custom-style{
  font-weight: 500;
  font-size: 15px;
  text-transform: capitalize;
  color: #333333;
}

.modal-header:before,.modal-header:after{
  content: none;
}
.margin-0{
  margin: 0px !important;

}
.padding-0{
  padding : 0px !important;
}
.margin-0.padding-0 span{
  color: #333333 !important;
  font-weight: 100;
}
.bg-theme{
  background-color: #eff0f5 !important;
}
.btn-padding{
  padding: 8px;
}
.bg-layer{
  display: flex;
  flex-direction: column;
  width: fit-content;
  border: 1px solid #ddd;
  padding: 0;
  max-width: fit-content;
  padding: 22px;
  margin-left: 30px !important;
  border-radius: 10px;
  background: rgba(255,255,255,0.9);
}
.text-theme{
  color: #333333 !important;
}
.bg-lightgrey .nice-select{
  background: #fafafa;
}
.padding-left-15{
  padding-left: 15px;
}

.custom-table-1{
  color: green;
}
.highlights-list{
  font-size: 20px;
}

.highlights-list:before{
  content: "";
  display: block;
  position: absolute;
  left: -34px;
  top: 5px;
  width: 12px;
  height: 12px;
  border: 1px solid #ed1c24;
  background: #ed1c24;
  border-radius: 50%;
  box-shadow: 0px 0px 0px 3px #fff inset;
  margin-left: 18px;
}
.source-now-btn{
  background-color: #fff;
  color: #1180b0;
  display: inline-block;
  padding: 7px 15px;
  border-radius: 50px;
}

.mute-color{
  font-weight: normal;
  color: #333333;
}

.store-country{
  font-weight: normal !important;
}

.highlights-container_list{
  padding: 0 !important;
}

a.text-dark.icon-text.icon-red{
  color: #f13f1a;
}




.product_img.img-border{
  border: 1px solid #ddd;
  border-radius: 5px;
  padding: 5px;
}
.notification-msg {
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
  border-bottom: 1px solid #ddd !important;
  background-color: white !important;
}
.notification-msg:hover {
  background-color: #F7FAFF !important;
}
.notification-dropdown{
  border-radius: 5px;
}
.user-header-dropdown .user-header-dropdown-header{
  margin: 0;
  padding: 0;
}
.user-header-dropdown .user-header-dropdown-header .user-email{
  padding: 12px 14px;
  border-bottom: 1px solid #ddd;

  margin: 0;
}
.user-header-dropdown .user-header-dropdown-header .user-email:hover{
  border-left: 5px solid #008cbd;
  background-color: #F7FAFF !important;
  cursor: pointer;
}

.user-header-dropdown .user-header-dropdown-header .user-name{
  padding: 12px 14px;

  margin: 0;
}
.user-header-dropdown .user-header-dropdown-header .user-name:hover{
  border-left: 5px solid #008cbd;
  background-color: #F7FAFF !important;
  cursor: pointer;
}

.user-header-dropdown .user-header-dropdown-links .user-header-link{
  padding: 12px 14px;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.user-header-dropdown .user-header-dropdown-links .user-header-link a{
  width: 100%;
  display: block;
}
.user-header-dropdown .user-header-dropdown-links .user-header-link:hover{
  border-left: 5px solid #008cbd;
  margin-left: -1px;
  background-color: #F7FAFF !important;
  cursor: pointer;
}
.user-header-dropdown .user-header-dropdown-links .user-header-link.logout{
  border-top: 0px !important;
  padding: 12px 14px;
  border-bottom: 1px solid #ddd;
  margin: 0;
}
.user-header-dropdown .user-header-dropdown-links .user-header-link.logout:hover{
  background-color: #F7FAFF !important;
  border-left: 5px solid #008cbd;
  cursor: pointer;
  margin-left: -1px;

}
.sign_and_login:hover .user-header-dropdown{
  padding: 0;
}
.no-messages-inner{
  min-height: 100px;
  /* width: 123px; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.header-icon-inner{
  width: 25px;
}
.input-group.mobile .nice-select{
  padding: 5px;
  height: 40px !important;
}
.input-group.mobile .nice-select:after{
  top: 6px;
  margin-right: 3px;
}
.input-group.mobile .current{
  font-size: 13px;
  text-align: left;
  margin-right: 10px;
}

/* Just a comment */
.whatsapp-sidebar{
  position: relative;
}
.whatsapp-sidebar.sidebar-header {
  border-bottom: 2px solid #1e8a8a;
  margin-bottom: 1rem;
}
.whatsapp-sidebar .sidebar-header h1 {
  margin-bottom: 0;
  display: inline-block;
  font-size: 1rem;
  font-weight: 600;
  padding: .5rem 0;
  color: #444;
  text-transform: capitalize;
}
.whatsapp-sidebar .list-sidebar {
  list-style: none;
  padding: 0;
}
.whatsapp-sidebar .list-sidebar li {
  padding: 5px 0;
}
.whatsapp-sidebar.list-sidebar li a {
  color: #444;
}
.custom-text-align{
  text-align: end;
}

@media (max-width: 600px){

  .header-icon_container li{
    margin-right: 0px;
  }
  .custom-text-align{
    text-align: start;
  }
}
