/** default and google theme **/
.selectstyle {
    position: relative;
}

.ss_dib {
    display: inline-block;
    vertical-align: top;
}

.ss_button {
    margin: 0;
    vertical-align: top;
    color: #595959;
    border: 1px solid #cbcbcb;
    border-radius: 3px;
    width: 250px;
    cursor: pointer;
    text-decoration: none;
    background: none;
    padding: 10px;
    overflow: hidden;
    clear: both;
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    font-weight: bold;
    word-break: break-all;
}

.ss_text {
    white-space: normal;
    text-overflow: ellipsis;
    text-indent: 0;
    white-space: nowrap;
    overflow: hidden;
}

.ss_image {
    position: absolute;
    width: 7px;
    height: 11px;
}

.ss_ul {
    list-style: none;
    padding: 8px 0;
    margin: 0;
    margin-top: -35px;
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    -moz-box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    display: none;
    overflow: auto;
    z-index: 100;
}

.ss_ulsearch {
    margin: 0;
    margin-top: -35px;
    position: absolute;
    background: #fff;
    border: 1px solid #ccc;
    -moz-box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    box-shadow: 0 1px 5px 1px rgba(0, 0, 0, 0.1);
    border-radius: 3px;
    -moz-border-radius: 3px;
    -webkit-border-radius: 3px;
    overflow: auto;
    z-index: 100;
    display: none;
}

.ss_ulsearch .ss_ul {
    display: block;
    position: relative;
    margin-top: 0;
    border: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.ss_ulsearch .search {
    border-bottom: 1px solid #ccc;
    padding: 0;
}

.ss_ulsearch .search input {
    background: inherit;
    border: none;
    height: 100%;
    padding: 10px 15px;
    outline: none;
    font-size: 14px;
    width: calc(100% - 30px);
    width: -webkit-calc(100% - 30px);
    width: -moz-calc(100% - 30px);
    width: -o-calc(100% - 30px);
    background-position: top 8px right 6px;
}

.ss_ulsearch .search input:focus {
    outline: none;
}

.ss_ulsearch .nosearch {
    text-align: center;
    font-size: 12px;
    padding-bottom: 10px;
    background: #fff;
}

.ss_ul li {
    padding: 10px 15px;
    cursor: pointer;
    border: none;
    text-align: left;
    color: #595959;
    transition-duration: .3s;
    -moz-transition-duration: .3s;
    -webkit-transition-duration: .3s;
    -o-transition-duration: .3s;
    font-size: 14px;
}

.ss_ul li:hover {
    border: none;
    background: #eee;
}

.ss_ul li .ssli_text {
    vertical-align: top;
    display: inline-block;
}

.ss_ul li .ssli_image {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin-right: 10px;
    margin-top: -1px;
    vertical-align: top;
}

.ss_ul li .ssli_image img {
    width: 16px;
    height: 16px;
}

.ss_ul::-webkit-scrollbar {
    width: 10px;
}

.ss_ul::-webkit-scrollbar:horizontal {
    height: 10px;
}

.ss_ul::-webkit-scrollbar-track {
    border-left: 1px solid #D3D3D3;
    background: #F5F5F5;
}

.ss_ul::-webkit-scrollbar-thumb {
    background: #C4C4C4;
}


/** Dark theme **/
.selectstyle.dark {
    position: relative;
}

.selectstyle.dark .ss_button {
    color: #eee;
    border: 1px solid #ccc;
    background: #5B5B5B;
}

.selectstyle.dark .ss_image {
    width: 16px;
    height: 16px;
    margin-left: -8px;
    margin-top: -2px;
}

.selectstyle.dark .ss_ul {
    background: #777;
    border-color: #333;
    -moz-box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
    -webkit-box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
    box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
}

.selectstyle.dark .ss_ulsearch .ss_ul {
    display: block;
    position: relative;
    margin-top: 0;
    border: none;
    -moz-box-shadow: none;
    -webkit-box-shadow: none;
    box-shadow: none;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}

.selectstyle.dark .ss_ulsearch {
    background: #5B5B5B;
    border-color: #333;
    -moz-box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
    -webkit-box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
    box-shadow: 0 1px 5px 1px rgba(255, 255, 255, 0.15);
}

.selectstyle.dark .ss_ulsearch .search {
    border-bottom-color: #333;
}

.selectstyle.dark .ss_ulsearch .search input {
    background-position: top 8px right 6px;
    background-size: 24px 24px;
    color: #eee;
}

.selectstyle.dark .ss_ulsearch .nosearch {
    background: #777;
}

.selectstyle.dark .ss_ul li {
    color: #ddd;
}

.selectstyle.dark .ss_ul li:hover {
    border: none;
    background: #444;
    color: #eee;
}

.selectstyle.dark .ss_ul::-webkit-scrollbar-track {
    border-left: 1px solid #333;
    background: #888;
}

.selectstyle.dark .ss_ul::-webkit-scrollbar-thumb {
    background: #5B5B5B;
}
