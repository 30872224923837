.content {
  margin-left: auto;
}
@media screen and (max-width: 2199px) {
  .service-img {
    position: relative !important;
    left: 0 !important;
    top: 0 !important;
  }
}
.home-side-banner11{
/*background-image: url("../public/static/images/banner3.jpg") ;*/
}
.home-side-banner111{
/*background-image: url("../public/static/images/banner2.jpg") ;*/
}
.home-side-banner1111{
/*background-image: url("../public/static/images/banner.jpg") ;*/
}
.stats-inner11 {
  /*background-image: url("../public/static/images/counter.jpg") ;*/
  border-radius: 6px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: left top;
  position: relative;
  padding: 25px 30px 25px 30px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  height: 100%;
  padding: 20px 50px;
}
input[type="radio"], input[type="checkbox"]{
  -webkit-appearance: checkbox !important;
}
.slide-submit {
  background-color: #fff;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
  border: 1px solid #d4d9e2;
  height: 40px;
  background: #f3f5f6;
  color: #929292;
  box-shadow: 0 0 0 1px #ccc;
  text-align: right;
  padding: 1em 1em;
  font-size: 0.75em;
  width: 100%;
  position: relative;
  overflow: hidden;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  transition: 0.2s;
}
.supplier_express_form > div {
  width: 100%;
  display: inline-flex;
  padding-bottom: 24px;
  align-items: center;
}
#slide-submitnextendbuy {
  display: grid;
  justify-content: center;
}
.slide-but {
  background-color: #fff !important;
  justify-content: end !important;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%) !important;
  border: 1px solid #d4d9e2 !important;
  height: 40px !important;
  min-width: 250px !important;
}
.slide-caret-wrapper {
  background: #fecb00 !important;
}
.slide-overlay {
  width: 40px;
  height: 100% !important;
  background: #fecb00 !important;
}
.form-control1 {
  background-color: white;
}
.PhoneInputInput {
  border: none;
}
.input-group .form-control1 {
  height: 40px;
  border: 1px solid #c5c5c5;
  padding: 12px;
}
.toast-container.jsx-3130614401{
  top: 169px !important;
  z-index: 10;
  opacity: 10;
}
.uploader__widget-base__modal-bg{
  z-index: 0 !important;
}
.toast-message-container.jsx-2781784856{
  /*position: relative !important;*/
}
select.form-control:not([size]):not([multiple]) {
  /*height: calc(3.25rem + 12px);*/
}
.button-styled {
  display: inline-flex !important;
  flex-wrap: nowrap !important;
  justify-content: center !important;
}
.info-card-container {
  width: -webkit-fill-available;
}
.message-tabs .nav-tabs > li.active a {
  color: #ffffff;
  background: #1180b0;
  border-bottom: 0px;
}
.navbar-default .navbar-toggle .icon-bar {
  background-color: #888;
}
.navbar-default .navbar11-toggle .icon-bar {
  background-color: #888;
}
.navbar-default .navbar-toggle {
  border-color: #ddd;
}
@media (max-width: 991px) {
  .navbar-header .collapse,
  .navbar11-toggle .navbar11-toggle {
    display: block;
  }
}
.data-table > thead > tr > th a {
  color: white;
}
.Toastify__progress-bar--default {
  background: red;
}
.Toastify__close-button .Toastify__close-button--default {
  -webkit-appearance: button;
  color: red;
}
.button_log {
  background-color: #1180b0;
  color: #fff;
  height: 50px;
  width: 100%;
  margin: 0;
  border-radius: 4px;
  letter-spacing: 2px;
  margin-top: 24px;
  font-family: Inter, poppins;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
}

@media only screen and (min-width: 750px) {
  .h1-elizeth {
    font-size: 2.688rem;
  }
}

.p2sec4 {
  font-size: 13px;
  font-weight: normal;
}

.h1-elizeth {
  font: 2.563rem Elizeth, Rund Text, Helvetica Neue, Helvetica, Arial, sans-serif;
  line-height: 1.25;
}
@media only screen and (min-width: 750px) {
  .h1-elizeth {
    font-size: 2.688rem;
  }
}

.icon-in-text-checkmark {
  font-size: 26px;
}

.pincode-input-text {
  background: #f2f3f7;
}

/*!
    * Bootstrap v4.6.0 (https://getbootstrap.com/)
    * Copyright 2011-2021 The Bootstrap Authors
    * Copyright 2011-2021 Twitter, Inc.
    * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
    */

.card .category,
.card .form-group > label {
  margin-bottom: 5px !important;
  display: block !important;
  font-size: 14px !important;
  color: #333333 !important;
  font-weight: normal !important;
  text-transform: capitalize !important;
}
.breadcrumb-container .category {
  position: absolute;
  width: 20px;
  right: 10px;
  top: 68%;
  transform: translateY(-50%);
}
.icon-category {
  margin-top: -23px;
  background-image: url(https://assets.abraacdn.com/assets/react-assets/img/icons/icon-category.png);
  background-repeat: no-repeat;
  background-position: center;
  height: 30px;
  display: block;
}

.spinner14-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
}
.spinner-block {
  height: 60px;
  width: 60px;
  margin: 0px auto;
  animation: rotation 0.6s infinite linear;
  border-top: 6px solid rgb(8 31 39 / 80%);
  border-radius: 100%;
}
.spinner14 {
  position: absolute;
  left: 50%;
  top: 50%;
  height: 60px;
  width: 60px;
  margin: 0px auto;
  -webkit-animation: rotation 0.6s infinite linear;
  -moz-animation: rotation 0.6s infinite linear;
  -o-animation: rotation 0.6s infinite linear;
  animation: rotation 0.6s infinite linear;
  border-left: 6px solid rgba(0, 174, 239, 0.15);
  border-right: 6px solid rgba(0, 174, 239, 0.15);
  border-bottom: 6px solid rgba(0, 174, 239, 0.15);
  border-top: 6px solid rgb(8 31 39 / 80%);
  border-radius: 100%;
}

@-webkit-keyframes rotation {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(359deg);
  }
}
@-moz-keyframes rotation {
  from {
    -moz-transform: rotate(0deg);
  }
  to {
    -moz-transform: rotate(359deg);
  }
}
@-o-keyframes rotation {
  from {
    -o-transform: rotate(0deg);
  }
  to {
    -o-transform: rotate(359deg);
  }
}
@keyframes rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(359deg);
  }
}

.spinner15-wrapper {
  height: 20vh;
  width: 50vh;
  top: 0;
  left: 0;
  background-color: rgb(255, 255, 255);
  z-index: 9999;
}

.d-flex-warp {
  display: flex;
  flex-wrap: wrap;
}
.fa-sort {
  color: #1180b0;
  font-size: 12px;
  position: absolute;
  right: 5px;
  width: 8px;
  line-height: 18px;
}
.fa,
.fas {
  margin-top: 6px;
  font-weight: 900;
}
.dropdown-toggle {
  padding: 5px 25px;
}
.tox-notifications-container {
  display: none !important;
}
.form-control {
  z-index: 2;
  float: left;
  min-height: 39px !important;
  width: 100%;
  margin-bottom: 0;
}
.NewSelect .css-13cymwt-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.NewSelect .css-t3ipsp-control .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.NewSelect .css-13cymwt-control .css-1fdsijx-ValueContainer .css-1jqq78o-placeholder {
  /* color: rgb(255, 255, 255); */
}
.NewSelect .react-select-41-placeholder {
  display: block !important;
}

.NewSelect .css-13cymwt-control {
  min-width: 82px;
}
.NewSelect .css-t3ipsp-control {
  min-width: 82px;
}
